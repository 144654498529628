import React, { useCallback, useState } from 'react';
import { useStyles } from './TruckCard.styles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { Box, Dialog, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';
import { CustomButton, UploadDocuments } from 'components';
import uploadIconSrc from 'assets/icons/upload_primary.svg';
import closeIconSrc from 'assets/svg/close.svg';
import { GetDocumentsQuery, GetDocumentTypesQuery } from 'generate/typesDMS';
import { getSupplyTrucks, supplyServiceClient } from 'graphqlapi';
import { useModalStateControls, useReadUserInfo, useResultContext } from 'hooks';
import { Truck, useDeleteTruckMutation } from 'generate/typesSupplyService';

const DOC_TYPES = ['log book', 'logbook', 'lease agreement'];

export const TruckCard = ({
	active,
	id,
	documentTypesData,
	assetDocumentsData,
	truckReg,
	refetchDocuments,
}: Truck & {
	documentTypesData?: GetDocumentTypesQuery;
	assetDocumentsData?: GetDocumentsQuery;
	refetchDocuments?: () => void;
}) => {
	const { t } = useTranslation('form-fields');
	const { t: tB } = useTranslation('buttons');
	const { t: tM } = useTranslation('modals');

	const classes = useStyles();
	const userInfo = useReadUserInfo();
	const { toggleResult, openErrorResult } = useResultContext();

	const status = active === true || active === null;
	const { isOpened, toggleModal } = useModalStateControls();
	const [open, setOpen] = useState(false);

	const assetDocumentTypes = (documentTypesData?.documentTypes || []).filter(
		documentType => (
			documentType?.label && DOC_TYPES.some(
				docType => documentType?.label?.toLowerCase().includes(docType)
			)
		)
	);

	const truckDocuments = (assetDocumentsData?.getDocuments || []).filter(
		truckDocument => truckDocument?.truckId === parseInt(id, 10)
	);

	const [deleteTruckMutation, { loading }] = useDeleteTruckMutation({
		client: supplyServiceClient,
		onCompleted: ({ deleteTruck }) => {
			toggleResult({
				text: deleteTruck?.message || tM('asset_deleted', { serial: truckReg }),
			});
		},
		onError: (error) => {
			openErrorResult(error, {
				title: tM('oops'),
				text: error?.message || '',
				type: 'error',
			});
		},
		refetchQueries: [
		  {
			query: getSupplyTrucks,
			variables: { transporterId: userInfo?.transporter_id, first: 100 },
		  },
		],
		awaitRefetchQueries: true,
	});

	// @ts-ignore
	const handleSubmit = useCallback(
		(id) => {
			deleteTruckMutation({
				variables: { id }
			})
		},
		[deleteTruckMutation]
	);

	const handleOpenDialogue = () => {
		setOpen(true);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	return (
		<ul className={classes.card}>
			<li className={classes.item}>
				<span>
					<Typography className={classes.title} variant="caption" component="p">
						{t(`request_fuel.truckReg`)}
					</Typography>
					<Typography className={classes.value} variant="body1" component="p">
						{truckReg}
					</Typography>
				</span>
				<div className={classes.extras}>
					<Button
						className={classes.iconButton}
						onClick={() => toggleModal()}
						startIcon={
							<img
								src={uploadIconSrc}
								alt='Upload Documents'
								className={classes.uploadIconButton}
							/>
						}
					/>
					<IconButton
						onClick={() => handleOpenDialogue()}
						className={classes.deleteIconButton}
						disabled={status}
					>
						<DeleteIcon />
					</IconButton>
					<Box
						className={status ? classes.activeStatus : classes.inactiveStatus}
						display="flex"
						alignItems="center"
					>
						{status ? <CheckIcon /> : <InfoIcon />}
						<Typography className={classes.statusText} variant="body1" component="p">
							{status ? 'Active' : 'Inactive'}
						</Typography>
					</Box>
					{isOpened && (
						<UploadDocuments
							// @ts-ignore
							transporterDocuments={truckDocuments || []}
							onClose={toggleModal}
							// @ts-ignore
							documentTypes={assetDocumentTypes || []}
							refetchDocuments={refetchDocuments}
							assetType='truck'
							assetId={parseInt(id, 10)}
						/>
					)}
					<Dialog open={open} className={classes.deleteAssetDialog}>
						<DialogContent>
							<Box textAlign='center' marginX='auto' marginBottom={2}>
								<img src={closeIconSrc} alt="close" />
							</Box>
							<Typography className={classes.deleteAsset}>
								{tM('delete_truck', { truckReg })}
							</Typography>
							<Box display='flex' flexDirection='column' marginY={3}>
								<CustomButton
									className={cn(classes.btn, classes.modalDismissButton)}
									onClick={handleCancel}
									data-qa="qa-close"
									variant='outlined'
								>
									{tB('no')}
								</CustomButton>
								<CustomButton
									className={cn(classes.btn, classes.confirmDelete)}
									data-qa="qa-proceed"
									type='submit'
									onClick={() => handleSubmit(id)}
									disabled={loading}
								>
									{tB('yes')}
								</CustomButton>
							</Box>
						</DialogContent>
					</Dialog>
				</div>
			</li>
		</ul>
	);
};
