import React, { useCallback, useMemo } from 'react';
import { CustomInputFieldContainerProps, CustomUploadField } from './CustomUploadField';
import { dmsClient } from 'graphqlapi';
import { useResultContext } from 'hooks';
import { useMixpanel } from 'analytics';
import { useUploadDocumentsMutation } from 'generate/typesDMS';

export function CustomUploadFieldAssets(props: CustomInputFieldContainerProps) {
	const { assetId, assetType } = props;
  const variables = useMemo(() => {
    if (assetType === 'truck') {
      return { truckId: assetId }
    }
    else if (assetType === 'trailer') {
      return { trailerId: assetId }
    }
    else if (assetType === 'driver') {
      return { driverId: assetId }
    }
  }, [assetType, assetId]);

	const { openErrorResult } = useResultContext();
	const mixpanel = useMixpanel();

  const [uploadDocumentsMutation, { loading }] = useUploadDocumentsMutation({
    client: dmsClient,
		onCompleted: () => {
      if(props && typeof props.refetch === 'function') {
        props.refetch();
      }
			mixpanel.track('Documents upload on the user management trucks page (Success)');
		},
		onError: (error) => {
			mixpanel.track('Documents upload on the user management trucks page (Failure)', { error });
			openErrorResult();
		},
  });

  // @ts-ignore
	const handleUpload = useCallback(
		(documentTypeId, file: File | FileList) => {
      uploadDocumentsMutation({
        variables: {
          input: {
            ...variables,
            source: "LTA",
            files: [file],
            documentTypeId,
          }
        }
      })
		},
		[variables, uploadDocumentsMutation]
	);

  // @ts-ignore
	return <CustomUploadField {...props} onUpload={handleUpload} isUploading={loading} />;
}
