import React, { useCallback, useEffect, useState } from 'react';
import { TrailerListTemp } from 'components';
import { TrailerBulkUpload } from 'containers';
import { useModalStateControls, useReadUserInfo } from 'hooks';
import { useGetAssetIdsQuery, useGetSupplyTrailersQuery } from 'generate/typesSupplyService';
import { AssetQueryContainer } from './AssetQueryContainer';
import { TrailerCard } from './TrailerCard';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';
import { GetDocumentsQuery, useGetDocumentsQuery, useGetDocumentTypesQuery } from 'generate/typesDMS';
import { dmsClient } from 'graphqlapi';
import { AssetType } from 'models/common/AssetType';

export const TrailerList = () => {
	const [trailerIds, setTrailerIds] = useState<Array<number | null | undefined>>();
	const [trailerDocumentsData, setTrailerDocumentsData] = useState<GetDocumentsQuery>();
	const { isOpened, toggleModal } = useModalStateControls();
	const userInfo = useReadUserInfo();

	const { data: documentTypesData } =
		useGetDocumentTypesQuery({
			client: dmsClient,
			variables: {
				region: userInfo?.region,
			},
			fetchPolicy: 'cache-and-network',
		});

	const { data: assetIdsData } =
		useGetAssetIdsQuery({
			client: supplyServiceClient,
			variables: {
				transporterId: userInfo.transporter_id as number,
				assetType: AssetType.Trailers,
			},
			fetchPolicy: 'cache-and-network',
			skip: !userInfo?.transporter_id
		});

	const fetchTrailerIds = useCallback(() => {
		if (assetIdsData?.assetIds) {
			setTrailerIds(assetIdsData.assetIds);
		}
	}, [assetIdsData?.assetIds, setTrailerIds]);

	useEffect(() => {
		fetchTrailerIds();
	}, [fetchTrailerIds]);

	const { data: getDocumentsData, refetch: refetchDocuments } =
		useGetDocumentsQuery({
			client: dmsClient,
			variables: { trailerIds: trailerIds as number[] },
			onCompleted: () => { },
			skip: !trailerIds,
			fetchPolicy: 'cache-and-network',
		});

	const fetchDocuments = useCallback(() => {
		if (getDocumentsData?.getDocuments) {
			setTrailerDocumentsData(getDocumentsData);
		}
	}, [getDocumentsData, setTrailerDocumentsData]);

	useEffect(() => {
		fetchDocuments();
	}, [fetchDocuments, trailerIds]);

	return (
		<AssetQueryContainer
			keyName="trailerReg"
			dataKey="trailers"
			useQuery={useGetSupplyTrailersQuery}
			recordKeyList={['trailerReg', 'active', 'id']}
			ItemTemplate={TrailerCard}
			options={{
				client: supplyServiceClient,
				variables: {
					transporterId: userInfo?.transporter_id,
          			first: 100
				},
			}}
			documentTypesData={documentTypesData}
			assetDocumentsData={trailerDocumentsData}
			refetchDocuments={refetchDocuments}
		>
			<TrailerListTemp onAddAsset={toggleModal} />
			<TrailerBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
		</AssetQueryContainer>
	);
};
