import React, {useRef} from 'react';
import { Field, FieldProps} from 'formik';
import { CustomInputProps} from 'components';
import {CustomButton} from 'components/button';
import {Box, Typography, LinearProgress} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';
import TakePhoto from './TakePhoto';
import uploadIconSrc from 'assets/icons/upload_primary.svg';
import {useTranslation} from 'react-i18next';
import {useMixpanel} from 'analytics';
import {useLocation} from 'react-router-dom';
import selectFileSrc from 'assets/svg/select_file.svg';
import {useStyles} from "./CustomUploadField.styles";


export interface CustomInputFieldContainerProps extends CustomInputProps {
    accept?: string;
    multiple?: boolean;
    isUploading?: boolean;
    newUpload?: boolean;
    assetId?: number;
    assetType?: string;
    refetch?: () => void;
}

interface CustomInputFieldProps<T> extends CustomInputFieldContainerProps {
    onUpload: (name: T, file: File | FileList) => void;
}

export function CustomUploadField<T extends string>({
        onUpload,
        name = '',
        label = '',
        accept,
        multiple,
        isUploading,
        newUpload = true,
        ...props
    }: CustomInputFieldProps<T>) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const {t} = useTranslation('form-fields');
    const {t: tM} = useTranslation('modals');
    const {t: tB} = useTranslation('buttons');
    const {t: tC} = useTranslation('common');
    const labelRef = useRef<HTMLInputElement>(null);
    const mixpanel = useMixpanel();
    const {pathname} = useLocation();
    const classes = useStyles();

    function handleClick() {
        mixpanel.track(`Upload document event (Trying to upload ${name} document)`, {
            pathname,
        });
        if (labelRef.current) {
            handlePopoverClose();
            labelRef.current.click();
        }
    }

    const handlePopoverClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Field name={name}>
                {({field: {value}, meta, form}: FieldProps) => {
                    const handleOnInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!e.target.files?.length) return;

                        if (multiple) {
                            const files = Array.from(e.target.files);

                            if (files) {
                                onUpload(name as T, e.target.files);
                                mixpanel.track(`${name} document uploaded successfully`, {
                                    pathname,
                                });
                                form.setFieldValue(name, files.map(({name}) => name).join(', '));
                            }
                        } else {
                            const file = e.target.files[0];

                            if (file) {
                                onUpload(name as T, file);
                                mixpanel.track(`${name} document uploaded successfully`, {
                                    pathname,
                                });
                                form.setFieldValue(name, file.name);
                            }
                        }
                    };

                    return (
                        <>
                            <input
                                id={name}
                                type="file"
                                ref={labelRef}
                                accept={accept}
                                hidden
                                onChange={handleOnInput}
                                multiple={multiple}
                            />

                            {newUpload ?
                                <CustomButton
                                    variant={"text"}
                                    className={classes.button}
                                    onClick={handlePopoverClick}
                                    autoWidth={true}
                                >
                                    <Box className={classes.container} marginTop={2} display="flex"
                                         flexDirection="column">
                                        {/*display uploading progress*/}
                                        {isUploading ? (
                                            <>
                                                <LinearProgress title="Uploading"/>
                                                <Typography className={classes.uploadProgress}>
                                                    {tB('uploading')}  {value?.name ?? value}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                               <Box display='flex' flexDirection='row'>
                                                   <img
                                                       src={uploadIconSrc}
                                                       alt={t('upload')}
                                                       width={15}
                                                       className={classes.icon}
                                                   />
                                                   <Typography className={classes.uploadedFile}>
                                                       {value ?? ''}
                                                   </Typography>
                                               </Box>
                                                <Typography className={classes.label}>
                                                    {tB('click_to_upload')}{label}
                                                </Typography></>
                                        )}

                                        <Typography className={classes.fileType}>
                                            {tC('completed_move_accepted_files')}
                                        </Typography>
                                    </Box>
                                </CustomButton> :
                                <>
                                    {isUploading &&
                                        <div className={classes.uploadProgress}>
                                            <LinearProgress title="Uploading"/>
                                            <Typography>
                                                {tB('uploading')}  {value?.name ?? value}
                                            </Typography>
                                        </div>
                                    }
                                    <CustomButton
                                        variant={"text"}
                                        className={classes.button}
                                        onClick={handlePopoverClick}
                                    >
                                        <Typography className={classes.title}>
                                            {tB('add_another_upload')}
                                        </Typography>
                                    </CustomButton>
                                </>}
                        </>
                    );
                }}
            </Field>
            <Dialog
                id={id}
                open={open}
                onClose={handlePopoverClose}
            >
                <DialogContent>
                    <Box height={234} p={0} display="flex" flexDirection="column" justifyContent="space-evenly"
                         alignItems="center">
                        <Typography variant="body2" align="left" className="text-medium">
                            {tM('select_upload_method')}
                        </Typography>
                        <Box height={90} p={2} display="flex" flexDirection="row">
                            <TakePhoto
                                // @ts-ignore
                                onUpload={onUpload}
                                handlePopoverClose={handlePopoverClose}
                                name={name}
                            />
                            <Button color="primary" onClick={handleClick}>
                                <img src={selectFileSrc} alt="select-file" id="select-file"/>
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>

            </Dialog>
        </>
    );
}
