import { gql } from '@apollo/client';

export const getDocuments = gql`
	query getDocuments(
    $moveId: Int
    $truckId: Int
    $driverId: Int
    $fuelId: Int
    $issueId: Int
    $stage: String
    $transporterId: Int
    $driverIds: [Int]
    $trailerIds: [Int]
    $truckIds: [Int]
  ) {
		getDocuments(
      moveId: $moveId
      truckId: $truckId
      driverId: $driverId
      fuelId: $fuelId
      issueId: $issueId
      stage: $stage
      transporterId: $transporterId
      driverIds: $driverIds
      trailerIds: $trailerIds
      truckIds: $truckIds
    ) {
    cargoClientId
    clearingAgentId
    documentType {
      label
      id
      category
      region
      stages
    }
      documentTypeId
      documentTypeLabel
      driverId
      fuelId
      id
      isArchived
      issueId
      moveId
      presignedUrl
      source
      trailerId
      transporterId
      truckId
      uploadDate
		}
	}
`;
