import React, { useCallback, useEffect, useState } from 'react';
import { DriverList as DriverListTemp } from 'components';
import { DriverBulkUpload } from 'containers';
import { useGetAssetIdsQuery, useGetSupplyDriversQuery } from 'generate/typesSupplyService';
import { useModalStateControls, useReadUserInfo } from 'hooks';
import { AssetQueryContainer } from './AssetQueryContainer';
import { DriverCard } from './DriverCard';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';
import { GetDocumentsQuery, useGetDocumentsQuery, useGetDocumentTypesQuery } from 'generate/typesDMS';
import { dmsClient } from 'graphqlapi';
import { AssetType } from 'models/common/AssetType';

export const DriverList = () => {
	const [driverIds, setDriverIds] = useState<Array<number | null | undefined>>();
	const [truckDocumentsData, setTruckDocumentsData] = useState<GetDocumentsQuery>();
	const { isOpened, toggleModal } = useModalStateControls();
	const userInfo = useReadUserInfo();

	const { data: documentTypesData } =
		useGetDocumentTypesQuery({
			client: dmsClient,
			variables: {
				region: userInfo?.region,
			},
			fetchPolicy: 'cache-and-network',
		});

	const { data: assetIdsData } =
		useGetAssetIdsQuery({
			client: supplyServiceClient,
			variables: {
				transporterId: userInfo.transporter_id as number,
				assetType: AssetType.Drivers,
			},
			fetchPolicy: 'cache-and-network',
			skip: !userInfo?.transporter_id
		});

	const fetchDriverIds = useCallback(() => {
		if (assetIdsData?.assetIds) {
			setDriverIds(assetIdsData.assetIds);
		}
	}, [assetIdsData?.assetIds, setDriverIds]);

	useEffect(() => {
		fetchDriverIds();
	}, [fetchDriverIds]);

	const { data: getDocumentsData, refetch: refetchDocuments } =
		useGetDocumentsQuery({
			client: dmsClient,
			variables: { driverIds: driverIds as number[] },
			onCompleted: () => { },
			skip: !driverIds,
			fetchPolicy: 'cache-and-network',
		});

	const fetchDocuments = useCallback(() => {
		if (getDocumentsData?.getDocuments) {
			setTruckDocumentsData(getDocumentsData);
		}
	}, [getDocumentsData, setTruckDocumentsData]);

	useEffect(() => {
		fetchDocuments();
	}, [fetchDocuments, driverIds]);

	return (
		<>
			<AssetQueryContainer
				keyName="id"
				dataKey="drivers"
				useQuery={useGetSupplyDriversQuery}
				recordKeyList={['names', 'id', 'phone', 'active']}
				ItemTemplate={DriverCard}
				options={{
					client: supplyServiceClient,
					variables: {
						transporterId: userInfo?.transporter_id,
            			first: 100,
					},
				}}
				documentTypesData={documentTypesData}
				assetDocumentsData={truckDocumentsData}
				refetchDocuments={refetchDocuments}
			>
				<DriverListTemp onAddAsset={toggleModal} />
				<DriverBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
			</AssetQueryContainer>
		</>
	);
};
