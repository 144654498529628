import { gql } from '@apollo/client';

export const deleteDriver = gql`
	mutation deleteDriver($id: Int!) {
		deleteDriver(id: $id) {
			message
			status
		}
	}
`;
