import React, { useCallback, useEffect, useState } from 'react';
import { TruckListTemp } from 'components';
import { TruckBulkUpload } from 'containers';
import { useGetAssetIdsQuery, useGetSupplyTrucksQuery } from 'generate/typesSupplyService';
import { useModalStateControls, useReadUserInfo } from 'hooks';
import { AssetQueryContainer } from './AssetQueryContainer';
import { TruckCard } from './TruckCard';
import { supplyServiceClient } from 'graphqlapi/clients/supplyServiceClient';
import { GetDocumentsQuery, useGetDocumentsQuery, useGetDocumentTypesQuery } from 'generate/typesDMS';
import { dmsClient } from 'graphqlapi';
import { AssetType } from 'models/common/AssetType';

export const TruckList = () => {
	const [truckIds, setTruckIds] = useState<Array<number | null | undefined>>();
	const [truckDocumentsData, setTruckDocumentsData] = useState<GetDocumentsQuery>();
	const { isOpened, toggleModal } = useModalStateControls();
	const userInfo = useReadUserInfo();

	const { data: documentTypesData } =
		useGetDocumentTypesQuery({
			client: dmsClient,
			variables: {
				region: userInfo?.region,
			},
			fetchPolicy: 'cache-and-network',
		});

	const { data: assetIdsData } =
		useGetAssetIdsQuery({
			client: supplyServiceClient,
			variables: {
				transporterId: userInfo.transporter_id as number,
				assetType: AssetType.Trucks,
			},
			fetchPolicy: 'cache-and-network',
			skip: !userInfo?.transporter_id
		});

	const fetchTruckIds = useCallback(() => {
		if (assetIdsData?.assetIds) {
			setTruckIds(assetIdsData.assetIds);
		}
	}, [assetIdsData?.assetIds, setTruckIds]);

	useEffect(() => {
		fetchTruckIds();
	}, [fetchTruckIds]);

	const { data: getDocumentsData, refetch: refetchDocuments } =
		useGetDocumentsQuery({
			client: dmsClient,
			variables: { truckIds: truckIds as number[] },
			onCompleted: () => { },
			skip: !truckIds,
			fetchPolicy: 'cache-and-network',
		});

	const fetchDocuments = useCallback(() => {
		if (getDocumentsData?.getDocuments) {
			setTruckDocumentsData(getDocumentsData);
		}
	}, [getDocumentsData, setTruckDocumentsData]);

	useEffect(() => {
		fetchDocuments();
	}, [fetchDocuments, truckIds]);

	return (
		<AssetQueryContainer
			keyName="truckReg"
			dataKey="trucks"
			useQuery={useGetSupplyTrucksQuery}
			recordKeyList={['truckReg', 'active', 'id']}
			ItemTemplate={TruckCard}
			// @ts-ignore
			options={{
				client: supplyServiceClient,
				variables: {
					transporterId: userInfo?.transporter_id,
          			first: 100,
				},
			}}
			documentTypesData={documentTypesData}
			assetDocumentsData={truckDocumentsData}
			refetchDocuments={refetchDocuments}
		>
			<TruckListTemp onAddAsset={toggleModal} />
			<TruckBulkUpload isOpened={isOpened} toggleModal={toggleModal} />
		</AssetQueryContainer>
	);
};
