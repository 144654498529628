import { gql } from '@apollo/client';

export const uploadedDocuments = gql`
	fragment uploadedDocuments on Document {
		directorIdentification
		certificateOfIncorporation
		kraPin
		tin
		kraTcc
		proofOfDirectorship
		carriersLiability
		carrierResolution
		gitCoverConfirmation
		insurancePolicies
		beeCertificate
		bankLetter
		vatCertificate
		registrationCertificate
		taxClearanceCertificate
		companyLetterhead
		directorsIds
		letterOfGoodStanding
		vehicleRegistrationDocuments
		qualityManagementCertificate
		directorKraPin
		goodsInTransitCertificate
		signedLoriContract
	}
`;
