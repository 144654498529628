import { gql } from '@apollo/client';

export const deleteTruck = gql`
	mutation deleteTruck($id: Int!) {
		deleteTruck(id: $id) {
			message
			status
		}
	}
`;
