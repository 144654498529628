export enum UploadDocumentationNames {
	carrierResolutionTest = 'carrierResolution',
	directorIdentification = 'directorIdentification',
	certificateOfIncorporation = 'certificateOfIncorporation',
	kraPin = 'kraPin',
	tin = 'tin', //Tax Identification Number
	kraTcc = 'kraTcc', // KRA Tax Compliance Certificate (TCC)
	proofOfDirectorship = 'proofOfDirectorship',
	carriersLiability = 'carriersLiability',
	carriersLiabilityExpiry = 'carriersLiabilityExpiry',

	gitConfirmation = 'gitCoverConfirmation',
	gitCoverExpiry = 'gitCoverExpiry',
	insurance = 'insurancePolicies',
	bee = 'beeCertificate',
	bankingLetter = 'bankLetter',
	companyRegistration = 'registrationCertificate',
	taxClearance = 'taxClearanceCertificate',
	letterhead = 'companyLetterhead',
	standingLetter = 'letterOfGoodStanding',
	ownerId = 'directorsIds',
	vehiclesReg = 'vehicleRegistrationDocuments',

	isVatRegistered = 'isVatRegistered',
	vatCertificate = 'vatCertificate',
	vatRegistrationNumber = 'vatRegistrationNumber',

	hasQualityManagementCert = 'hasQualityManagementCert',
	qualityCertificate = 'qualityManagementCertificate',
	directorKraPin = 'directorKraPin',
	goodsInTransit = 'goodsInTransitCertificate',
	signedLoriContract = 'signedLoriContract',
}
