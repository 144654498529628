/* eslint-disable */
// WARNING: This file is automatically generated. Do not edit.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  GenericScalar: any;
  Upload: any;
};

export type AccountManager = AccountManagerNode & {
  __typename?: 'AccountManager';
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mobile?: Maybe<Scalars['String']>;
  workMobile?: Maybe<Scalars['String']>;
};

export type AccountManagerConnection = {
  __typename?: 'AccountManagerConnection';
  edges: Array<Maybe<AccountManagerEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AccountManagerEdge = {
  __typename?: 'AccountManagerEdge';
  cursor: Scalars['String'];
  node?: Maybe<AccountManager>;
  other?: Maybe<Scalars['String']>;
};

export type AccountManagerNode = {
  id: Scalars['ID'];
};

export enum AccountManagerSortEnum {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MobileAsc = 'MOBILE_ASC',
  MobileDesc = 'MOBILE_DESC',
  WorkMobileAsc = 'WORK_MOBILE_ASC',
  WorkMobileDesc = 'WORK_MOBILE_DESC'
}

export enum ActiveEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Unconfirmed = 'UNCONFIRMED'
}

export enum AssetType {
  Drivers = 'DRIVERS',
  Trailers = 'TRAILERS',
  Trucks = 'TRUCKS'
}

export type BulkCreateCargoClients = {
  __typename?: 'BulkCreateCargoClients';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkCreateClearingAgents = {
  __typename?: 'BulkCreateClearingAgents';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkCreateDrivers = {
  __typename?: 'BulkCreateDrivers';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkCreateTrailers = {
  __typename?: 'BulkCreateTrailers';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkCreateTransporters = {
  __typename?: 'BulkCreateTransporters';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkCreateTrucks = {
  __typename?: 'BulkCreateTrucks';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkCreateVessels = {
  __typename?: 'BulkCreateVessels';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkImportDrivers = {
  __typename?: 'BulkImportDrivers';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  validationErrors?: Maybe<Scalars['GenericScalar']>;
};

export type BulkImportTrailers = {
  __typename?: 'BulkImportTrailers';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  validationErrors?: Maybe<Scalars['GenericScalar']>;
};

export type BulkImportTrucks = {
  __typename?: 'BulkImportTrucks';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  validationErrors?: Maybe<Scalars['GenericScalar']>;
};

export type BulkUpdateCargoClient = {
  __typename?: 'BulkUpdateCargoClient';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateCargoClientInput = {
  accountManager?: InputMaybe<Scalars['String']>;
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  agreedPaymentRate?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  auditorsName?: InputMaybe<Scalars['String']>;
  auditorsPhone?: InputMaybe<Scalars['String']>;
  auditorsPostalAddress?: InputMaybe<Scalars['String']>;
  bankAccountName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountType?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankBranch?: InputMaybe<Scalars['String']>;
  bankNumber?: InputMaybe<Scalars['Int']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryBusinessName?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  c2KraDocument?: InputMaybe<Scalars['Boolean']>;
  clientVendorForm?: InputMaybe<Scalars['Upload']>;
  companyBbbeeCertificate?: InputMaybe<Scalars['Upload']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyDateEstablished?: InputMaybe<Scalars['String']>;
  companyDirectorsDocument?: InputMaybe<Scalars['Upload']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyLetterhead?: InputMaybe<Scalars['Upload']>;
  companyLetterOfGoodStanding?: InputMaybe<Scalars['Upload']>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  companyPhone?: InputMaybe<Scalars['String']>;
  companyProofOfBanking?: InputMaybe<Scalars['Upload']>;
  companyRegisteredName?: InputMaybe<Scalars['String']>;
  companyRegistrationNumber?: InputMaybe<Scalars['String']>;
  companyStatutoryDocument?: InputMaybe<Scalars['Upload']>;
  companyTcc?: InputMaybe<Scalars['String']>;
  companyTradingName?: InputMaybe<Scalars['String']>;
  companyVatRegistrationNumber?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  containerizedCargo?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  creditLimitPeriod?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  directorName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  formOfEnterprise?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  idNumber?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['String']>;
  isBlacklisted?: InputMaybe<Scalars['String']>;
  isReceiver?: InputMaybe<Scalars['Boolean']>;
  isShipper?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  paymentCurrency?: InputMaybe<Scalars['String']>;
  paymentMode?: InputMaybe<Scalars['String']>;
  paymentPeriod?: InputMaybe<Scalars['String']>;
  paymentRateAmount?: InputMaybe<Scalars['String']>;
  paymentRateType?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  podAssignment?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  preferredCreditLimit?: InputMaybe<Scalars['String']>;
  preferredPaymentMode?: InputMaybe<Scalars['String']>;
  proofOfDelivery?: InputMaybe<Scalars['String']>;
  qboCustomerIdLocal?: InputMaybe<Scalars['String']>;
  qboCustomerIdUsd?: InputMaybe<Scalars['String']>;
  qboVendorIdLocal?: InputMaybe<Scalars['String']>;
  qboVendorIdUsd?: InputMaybe<Scalars['String']>;
  t1ExitNote?: InputMaybe<Scalars['Boolean']>;
  trakitEntityId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  weighbridgeSlips?: InputMaybe<Scalars['String']>;
};

export type BulkUpdateClearingAgent = {
  __typename?: 'BulkUpdateClearingAgent';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateClearingAgentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  certificateOfIncorporation?: InputMaybe<Scalars['Upload']>;
  code?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdOn?: InputMaybe<Scalars['String']>;
  docs?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  loriApproved?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
  vatCertificate?: InputMaybe<Scalars['Upload']>;
};

export type BulkUpdateDriver = {
  __typename?: 'BulkUpdateDriver';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateDriverInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  alternativePhone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  driverRates?: InputMaybe<Scalars['Int']>;
  drivingLicense?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  idNumber?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  portPass?: InputMaybe<Scalars['String']>;
  portPassExpiryDate?: InputMaybe<Scalars['DateTime']>;
  portPassPermanentLori?: InputMaybe<Scalars['String']>;
  portPassTempLori?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  trakitAssetId?: InputMaybe<Scalars['Int']>;
  trakitEntId?: InputMaybe<Scalars['Int']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  turnboyName?: InputMaybe<Scalars['String']>;
  turnboyNumber?: InputMaybe<Scalars['String']>;
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
};

export type BulkUpdateFuelStation = {
  __typename?: 'BulkUpdateFuelStation';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateFuelStationInput = {
  city?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fuelStationDiscount?: InputMaybe<Scalars['Int']>;
  geoId?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type BulkUpdateTrailer = {
  __typename?: 'BulkUpdateTrailer';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateTrailerInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  cargoCapacity?: InputMaybe<Scalars['Float']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  linkedTo?: InputMaybe<Scalars['Int']>;
  loadingType?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  trailerOnBoarded?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  trailerTypeId?: InputMaybe<Scalars['Int']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
  yom?: InputMaybe<Scalars['DateTime']>;
};

export type BulkUpdateTransporter = {
  __typename?: 'BulkUpdateTransporter';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateTransporterInput = {
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  accountManger?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  bankAccountCurrency?: InputMaybe<Scalars['String']>;
  bankAccountName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankNameBranch?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryBusinessName?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  directorName?: InputMaybe<Scalars['String']>;
  gitInsurance?: InputMaybe<Scalars['String']>;
  gitInsuranceExpiryDate?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  kraIn?: InputMaybe<Scalars['String']>;
  kraTcc?: InputMaybe<Scalars['Upload']>;
  kwatosCode?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  podAssignment?: InputMaybe<Scalars['String']>;
  portSticker?: InputMaybe<Scalars['String']>;
  proofOfDirectorship?: InputMaybe<Scalars['Upload']>;
  qboCustomerIdLocal?: InputMaybe<Scalars['String']>;
  qboCustomerIdUsd?: InputMaybe<Scalars['String']>;
  qboVendorIdLocal?: InputMaybe<Scalars['String']>;
  qboVendorIdUsd?: InputMaybe<Scalars['String']>;
  registrationCertificate?: InputMaybe<Scalars['Upload']>;
  roadWorthinessCertificate?: InputMaybe<Scalars['Upload']>;
  trakitEntityId?: InputMaybe<Scalars['Int']>;
  transporterPaymentPeriod?: InputMaybe<Scalars['String']>;
  transporterPaymentTerms?: InputMaybe<Scalars['Float']>;
};

export type BulkUpdateTruck = {
  __typename?: 'BulkUpdateTruck';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateTruckInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  cargoCapacity?: InputMaybe<Scalars['Float']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  ectsExpiry?: InputMaybe<Scalars['DateTime']>;
  ectsLicense?: InputMaybe<Scalars['String']>;
  ectsProvider?: InputMaybe<Scalars['String']>;
  gitInsurance?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  insuranceExpiry?: InputMaybe<Scalars['DateTime']>;
  insuranceProvider?: InputMaybe<Scalars['String']>;
  journeyHours?: InputMaybe<Scalars['Float']>;
  kwatos?: InputMaybe<Scalars['String']>;
  linkedTo?: InputMaybe<Scalars['Int']>;
  loadingType?: InputMaybe<Scalars['String']>;
  loriCaptiveScheme?: InputMaybe<Scalars['String']>;
  loriInsuranceScheme?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  match?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<Scalars['String']>;
  moveId?: InputMaybe<Scalars['Int']>;
  ntsaInspectionExpiry?: InputMaybe<Scalars['DateTime']>;
  onBoardingStatus?: InputMaybe<Scalars['String']>;
  portPass?: InputMaybe<Scalars['String']>;
  portPassExpiry?: InputMaybe<Scalars['DateTime']>;
  region?: InputMaybe<Scalars['String']>;
  registeredAtIcd?: InputMaybe<Scalars['String']>;
  roadWorthinessCertificate?: InputMaybe<Scalars['String']>;
  tglTransitPassComesa?: InputMaybe<Scalars['String']>;
  tglTransitPassExpiry?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  transitPass?: InputMaybe<Scalars['String']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Scalars['String']>;
  truckTypeId?: InputMaybe<Scalars['Int']>;
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
  yom?: InputMaybe<Scalars['DateTime']>;
};

export type BulkUpdateVessel = {
  __typename?: 'BulkUpdateVessel';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUpdateVesselInput = {
  country?: InputMaybe<Scalars['String']>;
  imo?: InputMaybe<Scalars['String']>;
  locDest?: InputMaybe<Scalars['String']>;
  mmsi?: InputMaybe<Scalars['Int']>;
  port?: InputMaybe<Scalars['String']>;
  received?: InputMaybe<Scalars['String']>;
  vesselName: Scalars['String'];
  vesselSize?: InputMaybe<Scalars['String']>;
};

export type BulkUploadDrivers = {
  __typename?: 'BulkUploadDrivers';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUploadTrailers = {
  __typename?: 'BulkUploadTrailers';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BulkUploadTrucks = {
  __typename?: 'BulkUploadTrucks';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CargoClient = CargoClientNode & {
  __typename?: 'CargoClient';
  accountManager?: Maybe<Scalars['String']>;
  accountManagerEmail?: Maybe<Scalars['String']>;
  agreedPaymentRate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  auditorsName?: Maybe<Scalars['String']>;
  auditorsPhone?: Maybe<Scalars['String']>;
  auditorsPostalAddress?: Maybe<Scalars['String']>;
  bankAccountName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['Int']>;
  bankAccountType?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankBranch?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankNumber?: Maybe<Scalars['Int']>;
  beneficiaryAddress?: Maybe<Scalars['String']>;
  beneficiaryBusinessName?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  c2KraDocument?: Maybe<Scalars['Boolean']>;
  clientVendorForm?: Maybe<Scalars['String']>;
  companyBbbeeCertificate?: Maybe<Scalars['String']>;
  companyCity?: Maybe<Scalars['String']>;
  companyDateEstablished?: Maybe<Scalars['DateTime']>;
  companyDirectorsDocument?: Maybe<Scalars['String']>;
  companyEmail?: Maybe<Scalars['String']>;
  companyLetterhead?: Maybe<Scalars['String']>;
  companyLetterOfGoodStanding?: Maybe<Scalars['String']>;
  companyLogo?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  companyProofOfBanking?: Maybe<Scalars['String']>;
  companyRegisteredName?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  companyStatutoryDocument?: Maybe<Scalars['String']>;
  companyTcc?: Maybe<Scalars['String']>;
  companyTradingName?: Maybe<Scalars['String']>;
  companyVatRegistrationNumber?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  contactPersonPhone?: Maybe<Scalars['String']>;
  containerizedCargo?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  creditLimitPeriod?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  directorName?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  firstName?: Maybe<Scalars['String']>;
  formOfEnterprise?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  isReceiver?: Maybe<Scalars['Boolean']>;
  isShipper?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  paymentCurrency?: Maybe<Scalars['String']>;
  paymentMode?: Maybe<Scalars['String']>;
  paymentPeriod?: Maybe<Scalars['String']>;
  paymentRateAmount?: Maybe<Scalars['String']>;
  paymentRateType?: Maybe<Scalars['String']>;
  physicalAddress?: Maybe<Scalars['String']>;
  podAssignment?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  preferredCreditLimit?: Maybe<Scalars['String']>;
  preferredPaymentMode?: Maybe<Scalars['String']>;
  proofOfDelivery?: Maybe<Scalars['String']>;
  qboCustomerIdLocal?: Maybe<Scalars['String']>;
  qboCustomerIdUsd?: Maybe<Scalars['String']>;
  qboVendorIdLocal?: Maybe<Scalars['String']>;
  qboVendorIdUsd?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  t1ExitNote?: Maybe<Scalars['Boolean']>;
  trakitEntityId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weighbridgeSlips?: Maybe<Scalars['Boolean']>;
};

export type CargoClientConnection = {
  __typename?: 'CargoClientConnection';
  edges: Array<Maybe<CargoClientEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CargoClientEdge = {
  __typename?: 'CargoClientEdge';
  cursor: Scalars['String'];
  node?: Maybe<CargoClient>;
  other?: Maybe<Scalars['String']>;
};

export type CargoClientNode = {
  id: Scalars['ID'];
};

export enum CargoClientSortEnum {
  AccountManagerAsc = 'ACCOUNT_MANAGER_ASC',
  AccountManagerDesc = 'ACCOUNT_MANAGER_DESC',
  AccountManagerEmailAsc = 'ACCOUNT_MANAGER_EMAIL_ASC',
  AccountManagerEmailDesc = 'ACCOUNT_MANAGER_EMAIL_DESC',
  AgreedPaymentRateAsc = 'AGREED_PAYMENT_RATE_ASC',
  AgreedPaymentRateDesc = 'AGREED_PAYMENT_RATE_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AuditorsNameAsc = 'AUDITORS_NAME_ASC',
  AuditorsNameDesc = 'AUDITORS_NAME_DESC',
  AuditorsPhoneAsc = 'AUDITORS_PHONE_ASC',
  AuditorsPhoneDesc = 'AUDITORS_PHONE_DESC',
  AuditorsPostalAddressAsc = 'AUDITORS_POSTAL_ADDRESS_ASC',
  AuditorsPostalAddressDesc = 'AUDITORS_POSTAL_ADDRESS_DESC',
  BankAccountNameAsc = 'BANK_ACCOUNT_NAME_ASC',
  BankAccountNameDesc = 'BANK_ACCOUNT_NAME_DESC',
  BankAccountNumberAsc = 'BANK_ACCOUNT_NUMBER_ASC',
  BankAccountNumberDesc = 'BANK_ACCOUNT_NUMBER_DESC',
  BankAccountTypeAsc = 'BANK_ACCOUNT_TYPE_ASC',
  BankAccountTypeDesc = 'BANK_ACCOUNT_TYPE_DESC',
  BankAddressAsc = 'BANK_ADDRESS_ASC',
  BankAddressDesc = 'BANK_ADDRESS_DESC',
  BankBranchAsc = 'BANK_BRANCH_ASC',
  BankBranchDesc = 'BANK_BRANCH_DESC',
  BankNameAsc = 'BANK_NAME_ASC',
  BankNameDesc = 'BANK_NAME_DESC',
  BankNumberAsc = 'BANK_NUMBER_ASC',
  BankNumberDesc = 'BANK_NUMBER_DESC',
  BeneficiaryAddressAsc = 'BENEFICIARY_ADDRESS_ASC',
  BeneficiaryAddressDesc = 'BENEFICIARY_ADDRESS_DESC',
  BeneficiaryBusinessNameAsc = 'BENEFICIARY_BUSINESS_NAME_ASC',
  BeneficiaryBusinessNameDesc = 'BENEFICIARY_BUSINESS_NAME_DESC',
  BillingAddressAsc = 'BILLING_ADDRESS_ASC',
  BillingAddressDesc = 'BILLING_ADDRESS_DESC',
  C2KraDocumentAsc = 'C2_KRA_DOCUMENT_ASC',
  C2KraDocumentDesc = 'C2_KRA_DOCUMENT_DESC',
  ClientVendorFormAsc = 'CLIENT_VENDOR_FORM_ASC',
  ClientVendorFormDesc = 'CLIENT_VENDOR_FORM_DESC',
  CompanyBbbeeCertificateAsc = 'COMPANY_BBBEE_CERTIFICATE_ASC',
  CompanyBbbeeCertificateDesc = 'COMPANY_BBBEE_CERTIFICATE_DESC',
  CompanyCityAsc = 'COMPANY_CITY_ASC',
  CompanyCityDesc = 'COMPANY_CITY_DESC',
  CompanyDateEstablishedAsc = 'COMPANY_DATE_ESTABLISHED_ASC',
  CompanyDateEstablishedDesc = 'COMPANY_DATE_ESTABLISHED_DESC',
  CompanyDirectorsDocumentAsc = 'COMPANY_DIRECTORS_DOCUMENT_ASC',
  CompanyDirectorsDocumentDesc = 'COMPANY_DIRECTORS_DOCUMENT_DESC',
  CompanyEmailAsc = 'COMPANY_EMAIL_ASC',
  CompanyEmailDesc = 'COMPANY_EMAIL_DESC',
  CompanyLetterOfGoodStandingAsc = 'COMPANY_LETTER_OF_GOOD_STANDING_ASC',
  CompanyLetterOfGoodStandingDesc = 'COMPANY_LETTER_OF_GOOD_STANDING_DESC',
  CompanyLetterheadAsc = 'COMPANY_LETTERHEAD_ASC',
  CompanyLetterheadDesc = 'COMPANY_LETTERHEAD_DESC',
  CompanyLogoAsc = 'COMPANY_LOGO_ASC',
  CompanyLogoDesc = 'COMPANY_LOGO_DESC',
  CompanyPhoneAsc = 'COMPANY_PHONE_ASC',
  CompanyPhoneDesc = 'COMPANY_PHONE_DESC',
  CompanyProofOfBankingAsc = 'COMPANY_PROOF_OF_BANKING_ASC',
  CompanyProofOfBankingDesc = 'COMPANY_PROOF_OF_BANKING_DESC',
  CompanyRegisteredNameAsc = 'COMPANY_REGISTERED_NAME_ASC',
  CompanyRegisteredNameDesc = 'COMPANY_REGISTERED_NAME_DESC',
  CompanyRegistrationNumberAsc = 'COMPANY_REGISTRATION_NUMBER_ASC',
  CompanyRegistrationNumberDesc = 'COMPANY_REGISTRATION_NUMBER_DESC',
  CompanyStatutoryDocumentAsc = 'COMPANY_STATUTORY_DOCUMENT_ASC',
  CompanyStatutoryDocumentDesc = 'COMPANY_STATUTORY_DOCUMENT_DESC',
  CompanyTccAsc = 'COMPANY_TCC_ASC',
  CompanyTccDesc = 'COMPANY_TCC_DESC',
  CompanyTradingNameAsc = 'COMPANY_TRADING_NAME_ASC',
  CompanyTradingNameDesc = 'COMPANY_TRADING_NAME_DESC',
  CompanyVatRegistrationNumberAsc = 'COMPANY_VAT_REGISTRATION_NUMBER_ASC',
  CompanyVatRegistrationNumberDesc = 'COMPANY_VAT_REGISTRATION_NUMBER_DESC',
  ContactPersonEmailAsc = 'CONTACT_PERSON_EMAIL_ASC',
  ContactPersonEmailDesc = 'CONTACT_PERSON_EMAIL_DESC',
  ContactPersonNameAsc = 'CONTACT_PERSON_NAME_ASC',
  ContactPersonNameDesc = 'CONTACT_PERSON_NAME_DESC',
  ContactPersonPhoneAsc = 'CONTACT_PERSON_PHONE_ASC',
  ContactPersonPhoneDesc = 'CONTACT_PERSON_PHONE_DESC',
  ContainerizedCargoAsc = 'CONTAINERIZED_CARGO_ASC',
  ContainerizedCargoDesc = 'CONTAINERIZED_CARGO_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreditLimitPeriodAsc = 'CREDIT_LIMIT_PERIOD_ASC',
  CreditLimitPeriodDesc = 'CREDIT_LIMIT_PERIOD_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DirectorNameAsc = 'DIRECTOR_NAME_ASC',
  DirectorNameDesc = 'DIRECTOR_NAME_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  FormOfEnterpriseAsc = 'FORM_OF_ENTERPRISE_ASC',
  FormOfEnterpriseDesc = 'FORM_OF_ENTERPRISE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IdNumberAsc = 'ID_NUMBER_ASC',
  IdNumberDesc = 'ID_NUMBER_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsBlacklistedAsc = 'IS_BLACKLISTED_ASC',
  IsBlacklistedDesc = 'IS_BLACKLISTED_DESC',
  IsReceiverAsc = 'IS_RECEIVER_ASC',
  IsReceiverDesc = 'IS_RECEIVER_DESC',
  IsShipperAsc = 'IS_SHIPPER_ASC',
  IsShipperDesc = 'IS_SHIPPER_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  PaymentCurrencyAsc = 'PAYMENT_CURRENCY_ASC',
  PaymentCurrencyDesc = 'PAYMENT_CURRENCY_DESC',
  PaymentModeAsc = 'PAYMENT_MODE_ASC',
  PaymentModeDesc = 'PAYMENT_MODE_DESC',
  PaymentPeriodAsc = 'PAYMENT_PERIOD_ASC',
  PaymentPeriodDesc = 'PAYMENT_PERIOD_DESC',
  PaymentRateAmountAsc = 'PAYMENT_RATE_AMOUNT_ASC',
  PaymentRateAmountDesc = 'PAYMENT_RATE_AMOUNT_DESC',
  PaymentRateTypeAsc = 'PAYMENT_RATE_TYPE_ASC',
  PaymentRateTypeDesc = 'PAYMENT_RATE_TYPE_DESC',
  PhysicalAddressAsc = 'PHYSICAL_ADDRESS_ASC',
  PhysicalAddressDesc = 'PHYSICAL_ADDRESS_DESC',
  PodAssignmentAsc = 'POD_ASSIGNMENT_ASC',
  PodAssignmentDesc = 'POD_ASSIGNMENT_DESC',
  PostalAddressAsc = 'POSTAL_ADDRESS_ASC',
  PostalAddressDesc = 'POSTAL_ADDRESS_DESC',
  PreferredCreditLimitAsc = 'PREFERRED_CREDIT_LIMIT_ASC',
  PreferredCreditLimitDesc = 'PREFERRED_CREDIT_LIMIT_DESC',
  PreferredPaymentModeAsc = 'PREFERRED_PAYMENT_MODE_ASC',
  PreferredPaymentModeDesc = 'PREFERRED_PAYMENT_MODE_DESC',
  ProofOfDeliveryAsc = 'PROOF_OF_DELIVERY_ASC',
  ProofOfDeliveryDesc = 'PROOF_OF_DELIVERY_DESC',
  QboCustomerIdLocalAsc = 'QBO_CUSTOMER_ID_LOCAL_ASC',
  QboCustomerIdLocalDesc = 'QBO_CUSTOMER_ID_LOCAL_DESC',
  QboCustomerIdUsdAsc = 'QBO_CUSTOMER_ID_USD_ASC',
  QboCustomerIdUsdDesc = 'QBO_CUSTOMER_ID_USD_DESC',
  QboVendorIdLocalAsc = 'QBO_VENDOR_ID_LOCAL_ASC',
  QboVendorIdLocalDesc = 'QBO_VENDOR_ID_LOCAL_DESC',
  QboVendorIdUsdAsc = 'QBO_VENDOR_ID_USD_ASC',
  QboVendorIdUsdDesc = 'QBO_VENDOR_ID_USD_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  T1ExitNoteAsc = 'T1_EXIT_NOTE_ASC',
  T1ExitNoteDesc = 'T1_EXIT_NOTE_DESC',
  TrakitEntityIdAsc = 'TRAKIT_ENTITY_ID_ASC',
  TrakitEntityIdDesc = 'TRAKIT_ENTITY_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WeighbridgeSlipsAsc = 'WEIGHBRIDGE_SLIPS_ASC',
  WeighbridgeSlipsDesc = 'WEIGHBRIDGE_SLIPS_DESC'
}

export type ClearingAgent = ClearingAgentNode & {
  __typename?: 'ClearingAgent';
  active?: Maybe<Scalars['Boolean']>;
  certificateOfIncorpotation?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  docs?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  loriApproved?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  vatCertificate?: Maybe<Scalars['String']>;
};

export type ClearingAgentConnection = {
  __typename?: 'ClearingAgentConnection';
  edges: Array<Maybe<ClearingAgentEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ClearingAgentEdge = {
  __typename?: 'ClearingAgentEdge';
  cursor: Scalars['String'];
  node?: Maybe<ClearingAgent>;
  other?: Maybe<Scalars['String']>;
};

export type ClearingAgentNode = {
  id: Scalars['ID'];
};

export enum ClearingAgentSortEnum {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CertificateOfIncorpotationAsc = 'CERTIFICATE_OF_INCORPOTATION_ASC',
  CertificateOfIncorpotationDesc = 'CERTIFICATE_OF_INCORPOTATION_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  ContactPersonAsc = 'CONTACT_PERSON_ASC',
  ContactPersonDesc = 'CONTACT_PERSON_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedOnAsc = 'CREATED_ON_ASC',
  CreatedOnDesc = 'CREATED_ON_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DocsAsc = 'DOCS_ASC',
  DocsDesc = 'DOCS_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LoriApprovedAsc = 'LORI_APPROVED_ASC',
  LoriApprovedDesc = 'LORI_APPROVED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PostalAddressAsc = 'POSTAL_ADDRESS_ASC',
  PostalAddressDesc = 'POSTAL_ADDRESS_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  TinAsc = 'TIN_ASC',
  TinDesc = 'TIN_DESC',
  VatCertificateAsc = 'VAT_CERTIFICATE_ASC',
  VatCertificateDesc = 'VAT_CERTIFICATE_DESC'
}

export type CreateCargoClient = {
  __typename?: 'CreateCargoClient';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateCargoClientInput = {
  accountManager?: InputMaybe<Scalars['String']>;
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  agreedPaymentRate?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  auditorsName?: InputMaybe<Scalars['String']>;
  auditorsPhone?: InputMaybe<Scalars['String']>;
  auditorsPostalAddress?: InputMaybe<Scalars['String']>;
  bankAccountName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountType?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankBranch?: InputMaybe<Scalars['String']>;
  bankNumber?: InputMaybe<Scalars['Int']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryBusinessName?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  c2KraDocument?: InputMaybe<Scalars['Boolean']>;
  clientVendorForm?: InputMaybe<Scalars['Upload']>;
  companyBbbeeCertificate?: InputMaybe<Scalars['Upload']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyDateEstablished?: InputMaybe<Scalars['String']>;
  companyDirectorsDocument?: InputMaybe<Scalars['Upload']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyLetterhead?: InputMaybe<Scalars['Upload']>;
  companyLetterOfGoodStanding?: InputMaybe<Scalars['Upload']>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  companyPhone?: InputMaybe<Scalars['String']>;
  companyProofOfBanking?: InputMaybe<Scalars['Upload']>;
  companyRegisteredName: Scalars['String'];
  companyRegistrationNumber?: InputMaybe<Scalars['String']>;
  companyStatutoryDocument?: InputMaybe<Scalars['Upload']>;
  companyTcc?: InputMaybe<Scalars['String']>;
  companyTradingName?: InputMaybe<Scalars['String']>;
  companyVatRegistrationNumber?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  containerizedCargo?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  creditLimitPeriod?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  directorName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  formOfEnterprise?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['String']>;
  isBlacklisted?: InputMaybe<Scalars['String']>;
  isReceiver?: InputMaybe<Scalars['Boolean']>;
  isShipper?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  paymentCurrency?: InputMaybe<Scalars['String']>;
  paymentMode?: InputMaybe<Scalars['String']>;
  paymentPeriod?: InputMaybe<Scalars['String']>;
  paymentRateAmount?: InputMaybe<Scalars['String']>;
  paymentRateType?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  podAssignment?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  preferredCreditLimit?: InputMaybe<Scalars['String']>;
  preferredPaymentMode?: InputMaybe<Scalars['String']>;
  proofOfDelivery?: InputMaybe<Scalars['String']>;
  qboCustomerIdLocal?: InputMaybe<Scalars['String']>;
  qboCustomerIdUsd?: InputMaybe<Scalars['String']>;
  qboVendorIdLocal?: InputMaybe<Scalars['String']>;
  qboVendorIdUsd?: InputMaybe<Scalars['String']>;
  t1ExitNote?: InputMaybe<Scalars['Boolean']>;
  trakitEntityId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  weighbridgeSlips?: InputMaybe<Scalars['String']>;
};

export type CreateClearingAgent = {
  __typename?: 'CreateClearingAgent';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateClearingAgentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  certificateOfIncorporation?: InputMaybe<Scalars['Upload']>;
  code?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdOn?: InputMaybe<Scalars['String']>;
  docs?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  loriApproved?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
  vatCertificate?: InputMaybe<Scalars['Upload']>;
};

export type CreateDocumentInput = {
  cargoClientId?: InputMaybe<Scalars['Int']>;
  clearingAgentId?: InputMaybe<Scalars['Int']>;
  driverId?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  source: Scalars['String'];
  trailerId?: InputMaybe<Scalars['Int']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckId?: InputMaybe<Scalars['Int']>;
  vesselName?: InputMaybe<Scalars['String']>;
};

export type CreateDriver = {
  __typename?: 'CreateDriver';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateDriverInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  alternativePhone?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  driverRates?: InputMaybe<Scalars['Int']>;
  drivingLicense?: InputMaybe<Scalars['String']>;
  idNumber: Scalars['String'];
  names: Scalars['String'];
  phone: Scalars['String'];
  portPass?: InputMaybe<Scalars['String']>;
  portPassExpiryDate?: InputMaybe<Scalars['DateTime']>;
  portPassPermanentLori?: InputMaybe<Scalars['String']>;
  portPassTempLori?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  trakitAssetId?: InputMaybe<Scalars['Int']>;
  trakitEntId?: InputMaybe<Scalars['Int']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  turnboyName?: InputMaybe<Scalars['String']>;
  turnboyNumber?: InputMaybe<Scalars['String']>;
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateFuelStation = {
  __typename?: 'CreateFuelStation';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateFuelStationInput = {
  city?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fuelStationDiscount?: InputMaybe<Scalars['Int']>;
  geoId?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type CreateRegion = {
  __typename?: 'CreateRegion';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateRegionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Date']>;
  createdBy?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  sourceApplication?: InputMaybe<Scalars['String']>;
};

export type CreateTrailer = {
  __typename?: 'CreateTrailer';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateTrailerInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  cargoCapacity: Scalars['Float'];
  country: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  linkedTo?: InputMaybe<Scalars['Int']>;
  loadingType?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  trailerOnBoarded?: InputMaybe<Scalars['String']>;
  trailerReg: Scalars['String'];
  trailerTypeId: Scalars['Int'];
  transporter?: InputMaybe<Scalars['String']>;
  transporterId: Scalars['Int'];
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
  yom?: InputMaybe<Scalars['DateTime']>;
};

export type CreateTrailerType = {
  __typename?: 'CreateTrailerType';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateTransporter = {
  __typename?: 'CreateTransporter';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateTransporterInput = {
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  accountManger?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  bankAccountCurrency?: InputMaybe<Scalars['String']>;
  bankAccountName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankNameBranch?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryBusinessName?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  contactPerson?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  directorName?: InputMaybe<Scalars['String']>;
  gitInsurance?: InputMaybe<Scalars['String']>;
  gitInsuranceExpiryDate?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  kraIn?: InputMaybe<Scalars['String']>;
  kraTcc?: InputMaybe<Scalars['Upload']>;
  kwatosCode?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  podAssignment?: InputMaybe<Scalars['String']>;
  portSticker?: InputMaybe<Scalars['String']>;
  proofOfDirectorship?: InputMaybe<Scalars['Upload']>;
  qboCustomerIdLocal?: InputMaybe<Scalars['String']>;
  qboCustomerIdUsd?: InputMaybe<Scalars['String']>;
  qboVendorIdLocal?: InputMaybe<Scalars['String']>;
  qboVendorIdUsd?: InputMaybe<Scalars['String']>;
  registrationCertificate?: InputMaybe<Scalars['Upload']>;
  roadWorthinessCertificate?: InputMaybe<Scalars['Upload']>;
  trakitEntityId?: InputMaybe<Scalars['Int']>;
  transporterPaymentPeriod?: InputMaybe<Scalars['String']>;
  transporterPaymentTerms?: InputMaybe<Scalars['Float']>;
};

export type CreateTruck = {
  __typename?: 'CreateTruck';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateTruckInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  cargoCapacity?: InputMaybe<Scalars['Float']>;
  country: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  ectsExpiry?: InputMaybe<Scalars['DateTime']>;
  ectsLicense?: InputMaybe<Scalars['String']>;
  ectsProvider?: InputMaybe<Scalars['String']>;
  gitInsurance?: InputMaybe<Scalars['String']>;
  insuranceExpiry?: InputMaybe<Scalars['DateTime']>;
  insuranceProvider?: InputMaybe<Scalars['String']>;
  journeyHours?: InputMaybe<Scalars['Float']>;
  kwatos?: InputMaybe<Scalars['String']>;
  linkedTo?: InputMaybe<Scalars['Int']>;
  loadingType?: InputMaybe<Scalars['String']>;
  loriCaptiveScheme?: InputMaybe<Scalars['String']>;
  loriInsuranceScheme?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  match?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<Scalars['String']>;
  moveId?: InputMaybe<Scalars['Int']>;
  ntsaInspectionExpiry?: InputMaybe<Scalars['DateTime']>;
  onBoardingStatus?: InputMaybe<Scalars['String']>;
  portPass?: InputMaybe<Scalars['String']>;
  portPassExpiry?: InputMaybe<Scalars['DateTime']>;
  region?: InputMaybe<Scalars['String']>;
  registeredAtIcd?: InputMaybe<Scalars['String']>;
  roadWorthinessCertificate?: InputMaybe<Scalars['String']>;
  tglTransitPassComesa?: InputMaybe<Scalars['String']>;
  tglTransitPassExpiry?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  transitPass?: InputMaybe<Scalars['String']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId: Scalars['Int'];
  truckReg: Scalars['String'];
  truckTypeId: Scalars['Int'];
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
  yom?: InputMaybe<Scalars['DateTime']>;
};

export type CreateTruckType = {
  __typename?: 'CreateTruckType';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateVessel = {
  __typename?: 'CreateVessel';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type CreateVesselInput = {
  country?: InputMaybe<Scalars['String']>;
  imo?: InputMaybe<Scalars['String']>;
  locDest?: InputMaybe<Scalars['String']>;
  mmsi?: InputMaybe<Scalars['Int']>;
  port?: InputMaybe<Scalars['String']>;
  received?: InputMaybe<Scalars['String']>;
  vesselName: Scalars['String'];
  vesselSize?: InputMaybe<Scalars['String']>;
};

export type DeactivateRegion = {
  __typename?: 'DeactivateRegion';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteCargoClient = {
  __typename?: 'DeleteCargoClient';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteClearingAgent = {
  __typename?: 'DeleteClearingAgent';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteDocument = {
  __typename?: 'DeleteDocument';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteDriver = {
  __typename?: 'DeleteDriver';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteFuelStation = {
  __typename?: 'DeleteFuelStation';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteTrailer = {
  __typename?: 'DeleteTrailer';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteTrailerType = {
  __typename?: 'DeleteTrailerType';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteTransporter = {
  __typename?: 'DeleteTransporter';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteTruck = {
  __typename?: 'DeleteTruck';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteTruckType = {
  __typename?: 'DeleteTruckType';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type DeleteVessel = {
  __typename?: 'DeleteVessel';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type Document = DocumentsNode & {
  __typename?: 'Document';
  CargoClient?: Maybe<CargoClient>;
  cargoClientId?: Maybe<Scalars['Int']>;
  ClearingAgent?: Maybe<ClearingAgent>;
  clearingAgentId?: Maybe<Scalars['Int']>;
  document?: Maybe<Truck>;
  documentTypeId?: Maybe<Scalars['Int']>;
  driverId?: Maybe<Scalars['Int']>;
  drivers?: Maybe<Driver>;
  fuelId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  issueId?: Maybe<Scalars['Int']>;
  moveId?: Maybe<Scalars['Int']>;
  s3Key?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  trailerId?: Maybe<Scalars['Int']>;
  trailers?: Maybe<Trailer>;
  transporter?: Maybe<Transporter>;
  transporterId?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['Int']>;
  uploadDate?: Maybe<Scalars['DateTime']>;
};

export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  edges: Array<Maybe<DocumentEdge>>;
  pageInfo: PageInfo;
};

export type DocumentConnectionsConnection = {
  __typename?: 'DocumentConnectionsConnection';
  edges: Array<Maybe<DocumentConnectionsEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DocumentConnectionsEdge = {
  __typename?: 'DocumentConnectionsEdge';
  cursor: Scalars['String'];
  node?: Maybe<Document>;
  other?: Maybe<Scalars['String']>;
};

export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  cursor: Scalars['String'];
  node?: Maybe<Document>;
};

export type DocumentsNode = {
  id: Scalars['ID'];
};

export enum DocumentSortEnum {
  CargoClientIdAsc = 'CARGO_CLIENT_ID_ASC',
  CargoClientIdDesc = 'CARGO_CLIENT_ID_DESC',
  ClearingAgentIdAsc = 'CLEARING_AGENT_ID_ASC',
  ClearingAgentIdDesc = 'CLEARING_AGENT_ID_DESC',
  DocumentTypeIdAsc = 'DOCUMENT_TYPE_ID_ASC',
  DocumentTypeIdDesc = 'DOCUMENT_TYPE_ID_DESC',
  DriverIdAsc = 'DRIVER_ID_ASC',
  DriverIdDesc = 'DRIVER_ID_DESC',
  FuelIdAsc = 'FUEL_ID_ASC',
  FuelIdDesc = 'FUEL_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IssueIdAsc = 'ISSUE_ID_ASC',
  IssueIdDesc = 'ISSUE_ID_DESC',
  MoveIdAsc = 'MOVE_ID_ASC',
  MoveIdDesc = 'MOVE_ID_DESC',
  S3KeyAsc = 'S3_KEY_ASC',
  S3KeyDesc = 'S3_KEY_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TrailerIdAsc = 'TRAILER_ID_ASC',
  TrailerIdDesc = 'TRAILER_ID_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TruckIdAsc = 'TRUCK_ID_ASC',
  TruckIdDesc = 'TRUCK_ID_DESC',
  UploadDateAsc = 'UPLOAD_DATE_ASC',
  UploadDateDesc = 'UPLOAD_DATE_DESC'
}

export type DocumentType = DocumentTypeNode & {
  __typename?: 'DocumentType';
  category?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stages?: Maybe<Scalars['String']>;
};

export type DocumentTypeConnection = {
  __typename?: 'DocumentTypeConnection';
  edges: Array<Maybe<DocumentTypeEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DocumentTypeEdge = {
  __typename?: 'DocumentTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<DocumentType>;
  other?: Maybe<Scalars['String']>;
};

export type DocumentTypeNode = {
  id: Scalars['ID'];
};

export enum DocumentTypeSortEnum {
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  StagesAsc = 'STAGES_ASC',
  StagesDesc = 'STAGES_DESC'
}

export type Driver = DriverNode & {
  __typename?: 'Driver';
  active?: Maybe<Scalars['Boolean']>;
  alternativePhone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Document>;
  driverRates?: Maybe<Scalars['Int']>;
  drivingLicense?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  idNumber?: Maybe<Scalars['String']>;
  linkedTo?: Maybe<Scalars['String']>;
  names?: Maybe<Scalars['String']>;
  onjourneyTruck?: Maybe<Truck>;
  onjourneyTruckId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  portPass?: Maybe<Scalars['String']>;
  portPassExpiryDate?: Maybe<Scalars['DateTime']>;
  portPassPermanentLori?: Maybe<Scalars['String']>;
  portPassTempLori?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  trakitAssetId?: Maybe<Scalars['Int']>;
  trakitEntId?: Maybe<Scalars['Int']>;
  transporter?: Maybe<Transporter>;
  transporterId?: Maybe<Scalars['Int']>;
  truck?: Maybe<Truck>;
  truckId?: Maybe<Scalars['Int']>;
  turnboyName?: Maybe<Scalars['String']>;
  turnboyNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vettedBy?: Maybe<Scalars['String']>;
  vettedDate?: Maybe<Scalars['DateTime']>;
};

export type DriverAvailability = {
  __typename?: 'DriverAvailability';
  driverId?: Maybe<Scalars['Int']>;
  idNumber?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type DriverConnection = {
  __typename?: 'DriverConnection';
  edges: Array<Maybe<DriverEdge>>;
  pageInfo: PageInfo;
};

export type DriverConnectionsConnection = {
  __typename?: 'DriverConnectionsConnection';
  edges: Array<Maybe<DriverConnectionsEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type DriverConnectionsEdge = {
  __typename?: 'DriverConnectionsEdge';
  cursor: Scalars['String'];
  node?: Maybe<Driver>;
  other?: Maybe<Scalars['String']>;
};

export type DriverEdge = {
  __typename?: 'DriverEdge';
  cursor: Scalars['String'];
  node?: Maybe<Driver>;
};

export type DriverLinkingResults = {
  __typename?: 'DriverLinkingResults';
  driverId?: Maybe<Scalars['Int']>;
  idNumber?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  truckSerial?: Maybe<Scalars['String']>;
};

export type DriverList = {
  idNumber: Scalars['String'];
  truckSerial: Scalars['String'];
};

export type DriverNode = {
  id: Scalars['ID'];
};

export type DriverReleaseList = {
  idNumber: Scalars['String'];
  nextStage?: InputMaybe<ReleaseStageEnum>;
};

export enum DriverSortEnum {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  AlternativePhoneAsc = 'ALTERNATIVE_PHONE_ASC',
  AlternativePhoneDesc = 'ALTERNATIVE_PHONE_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DriverRatesAsc = 'DRIVER_RATES_ASC',
  DriverRatesDesc = 'DRIVER_RATES_DESC',
  DrivingLicenseAsc = 'DRIVING_LICENSE_ASC',
  DrivingLicenseDesc = 'DRIVING_LICENSE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IdNumberAsc = 'ID_NUMBER_ASC',
  IdNumberDesc = 'ID_NUMBER_DESC',
  NamesAsc = 'NAMES_ASC',
  NamesDesc = 'NAMES_DESC',
  OnjourneyTruckIdAsc = 'ONJOURNEY_TRUCK_ID_ASC',
  OnjourneyTruckIdDesc = 'ONJOURNEY_TRUCK_ID_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PortPassAsc = 'PORT_PASS_ASC',
  PortPassDesc = 'PORT_PASS_DESC',
  PortPassExpiryDateAsc = 'PORT_PASS_EXPIRY_DATE_ASC',
  PortPassExpiryDateDesc = 'PORT_PASS_EXPIRY_DATE_DESC',
  PortPassPermanentLoriAsc = 'PORT_PASS_PERMANENT_LORI_ASC',
  PortPassPermanentLoriDesc = 'PORT_PASS_PERMANENT_LORI_DESC',
  PortPassTempLoriAsc = 'PORT_PASS_TEMP_LORI_ASC',
  PortPassTempLoriDesc = 'PORT_PASS_TEMP_LORI_DESC',
  RatingAsc = 'RATING_ASC',
  RatingDesc = 'RATING_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  SerialAsc = 'SERIAL_ASC',
  SerialDesc = 'SERIAL_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TrakitAssetIdAsc = 'TRAKIT_ASSET_ID_ASC',
  TrakitAssetIdDesc = 'TRAKIT_ASSET_ID_DESC',
  TrakitEntIdAsc = 'TRAKIT_ENT_ID_ASC',
  TrakitEntIdDesc = 'TRAKIT_ENT_ID_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TruckIdAsc = 'TRUCK_ID_ASC',
  TruckIdDesc = 'TRUCK_ID_DESC',
  TurnboyNameAsc = 'TURNBOY_NAME_ASC',
  TurnboyNameDesc = 'TURNBOY_NAME_DESC',
  TurnboyNumberAsc = 'TURNBOY_NUMBER_ASC',
  TurnboyNumberDesc = 'TURNBOY_NUMBER_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VettedByAsc = 'VETTED_BY_ASC',
  VettedByDesc = 'VETTED_BY_DESC',
  VettedDateAsc = 'VETTED_DATE_ASC',
  VettedDateDesc = 'VETTED_DATE_DESC'
}

export type Entity = EntityNode & {
  __typename?: 'Entity';
  companyEmail?: Maybe<Scalars['String']>;
  companyMobile?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type EntityConnection = {
  __typename?: 'EntityConnection';
  edges: Array<Maybe<EntityEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type EntityEdge = {
  __typename?: 'EntityEdge';
  cursor: Scalars['String'];
  node?: Maybe<Entity>;
  other?: Maybe<Scalars['String']>;
};

export type EntityNode = {
  id: Scalars['ID'];
};

export enum EntitySortEnum {
  CompanyEmailAsc = 'COMPANY_EMAIL_ASC',
  CompanyEmailDesc = 'COMPANY_EMAIL_DESC',
  CompanyMobileAsc = 'COMPANY_MOBILE_ASC',
  CompanyMobileDesc = 'COMPANY_MOBILE_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC'
}

export type FuelStation = FuelStationNode & {
  __typename?: 'FuelStation';
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fuelStationDiscount?: Maybe<Scalars['Int']>;
  geoId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type FuelStationConnection = {
  __typename?: 'FuelStationConnection';
  edges: Array<Maybe<FuelStationEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type FuelStationEdge = {
  __typename?: 'FuelStationEdge';
  cursor: Scalars['String'];
  node?: Maybe<FuelStation>;
  other?: Maybe<Scalars['String']>;
};

export type FuelStationNode = {
  id: Scalars['ID'];
};

export enum FuelStationSortEnum {
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FuelStationDiscountAsc = 'FUEL_STATION_DISCOUNT_ASC',
  FuelStationDiscountDesc = 'FUEL_STATION_DISCOUNT_DESC',
  GeoIdAsc = 'GEO_ID_ASC',
  GeoIdDesc = 'GEO_ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  OwnerNameAsc = 'OWNER_NAME_ASC',
  OwnerNameDesc = 'OWNER_NAME_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  RemarksAsc = 'REMARKS_ASC',
  RemarksDesc = 'REMARKS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type LinkDrivers = {
  __typename?: 'LinkDrivers';
  processedDrivers?: Maybe<Array<Maybe<DriverLinkingResults>>>;
};

export enum LinkEnum {
  Linked = 'LINKED',
  Onjourney = 'ONJOURNEY',
  Unlinked = 'UNLINKED'
}

export type LinkTrailers = {
  __typename?: 'LinkTrailers';
  processedTrailers?: Maybe<Array<Maybe<TrailerLinkingResults>>>;
};

export enum MatchEnum {
  Matched = 'MATCHED',
  Onjourney = 'ONJOURNEY',
  Unmatched = 'UNMATCHED'
}

export type MatchingResults = {
  __typename?: 'MatchingResults';
  match?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  moveId?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['Int']>;
};

export type MatchTrucks = {
  __typename?: 'MatchTrucks';
  processedTrucks?: Maybe<Array<Maybe<MatchingResults>>>;
};

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Region = RegionNode & {
  __typename?: 'Region';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sourceApplication?: Maybe<Scalars['String']>;
};

export type RegionConnection = {
  __typename?: 'RegionConnection';
  edges: Array<Maybe<RegionEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RegionEdge = {
  __typename?: 'RegionEdge';
  cursor: Scalars['String'];
  node?: Maybe<Region>;
  other?: Maybe<Scalars['String']>;
};

export enum RegionEnum {
  Ea = 'EA',
  Sa = 'SA',
  Wa = 'WA'
}

export type RegionNode = {
  id: Scalars['ID'];
};

export enum RegionSortEnum {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SourceApplicationAsc = 'SOURCE_APPLICATION_ASC',
  SourceApplicationDesc = 'SOURCE_APPLICATION_DESC'
}

export type ReleaseDrivers = {
  __typename?: 'ReleaseDrivers';
  processedDrivers?: Maybe<Array<Maybe<DriverLinkingResults>>>;
};

export enum ReleaseStageEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Onjourney = 'ONJOURNEY'
}

export type ReleaseTrailers = {
  __typename?: 'ReleaseTrailers';
  processedTrailers?: Maybe<Array<Maybe<TrailerLinkingResults>>>;
};

export type ReleaseTrucks = {
  __typename?: 'ReleaseTrucks';
  processedTrucks?: Maybe<Array<Maybe<MatchingResults>>>;
};

export type RestoreCargoClient = {
  __typename?: 'RestoreCargoClient';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreClearingAgent = {
  __typename?: 'RestoreClearingAgent';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreDriver = {
  __typename?: 'RestoreDriver';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreFuelStation = {
  __typename?: 'RestoreFuelStation';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreRegion = {
  __typename?: 'RestoreRegion';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreTrailer = {
  __typename?: 'RestoreTrailer';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreTransporter = {
  __typename?: 'RestoreTransporter';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreTruck = {
  __typename?: 'RestoreTruck';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RestoreVessel = {
  __typename?: 'RestoreVessel';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  bulkCreateCargoClients?: Maybe<BulkCreateCargoClients>;
  bulkCreateClearingAgents?: Maybe<BulkCreateClearingAgents>;
  bulkCreateDrivers?: Maybe<BulkCreateDrivers>;
  bulkCreateTrailers?: Maybe<BulkCreateTrailers>;
  bulkCreateTransporters?: Maybe<BulkCreateTransporters>;
  bulkCreateTrucks?: Maybe<BulkCreateTrucks>;
  bulkCreateVessel?: Maybe<BulkCreateVessels>;
  bulkImportDrivers?: Maybe<BulkImportDrivers>;
  bulkImportTrailers?: Maybe<BulkImportTrailers>;
  bulkImportTrucks?: Maybe<BulkImportTrucks>;
  bulkUpdateCargoClients?: Maybe<BulkUpdateCargoClient>;
  bulkUpdateClearingAgent?: Maybe<BulkUpdateClearingAgent>;
  bulkUpdateDriver?: Maybe<BulkUpdateDriver>;
  bulkUpdateFuelStation?: Maybe<BulkUpdateFuelStation>;
  bulkUpdateTrailers?: Maybe<BulkUpdateTrailer>;
  bulkUpdateTransporter?: Maybe<BulkUpdateTransporter>;
  bulkUpdateTrucks?: Maybe<BulkUpdateTruck>;
  bulkUpdateVessel?: Maybe<BulkUpdateVessel>;
  bulkUploadDrivers?: Maybe<BulkUploadDrivers>;
  bulkUploadTrailers?: Maybe<BulkUploadTrailers>;
  bulkUploadTrucks?: Maybe<BulkUploadTrucks>;
  createCargoClient?: Maybe<CreateCargoClient>;
  createClearingAgent?: Maybe<CreateClearingAgent>;
  createDriver?: Maybe<CreateDriver>;
  createFuelStation?: Maybe<CreateFuelStation>;
  createRegion?: Maybe<CreateRegion>;
  createTrailer?: Maybe<CreateTrailer>;
  createTrailerType?: Maybe<CreateTrailerType>;
  createTransporter?: Maybe<CreateTransporter>;
  createTruck?: Maybe<CreateTruck>;
  createTruckType?: Maybe<CreateTruckType>;
  createVessel?: Maybe<CreateVessel>;
  deactivateRegion?: Maybe<DeactivateRegion>;
  deleteCargoClient?: Maybe<DeleteCargoClient>;
  deleteClearingAgent?: Maybe<DeleteClearingAgent>;
  deleteDocument?: Maybe<DeleteDocument>;
  deleteDriver?: Maybe<DeleteDriver>;
  deleteFuelStation?: Maybe<DeleteFuelStation>;
  deleteTrailer?: Maybe<DeleteTrailer>;
  deleteTrailerType?: Maybe<DeleteTrailerType>;
  deleteTransporter?: Maybe<DeleteTransporter>;
  deleteTruck?: Maybe<DeleteTruck>;
  deleteTruckType?: Maybe<DeleteTruckType>;
  deleteVessel?: Maybe<DeleteVessel>;
  linkDrivers?: Maybe<LinkDrivers>;
  linkTrailers?: Maybe<LinkTrailers>;
  matchTrucks?: Maybe<MatchTrucks>;
  releaseDrivers?: Maybe<ReleaseDrivers>;
  releaseTrailers?: Maybe<ReleaseTrailers>;
  releaseTrucks?: Maybe<ReleaseTrucks>;
  restoreCargoClient?: Maybe<RestoreCargoClient>;
  restoreClearingAgent?: Maybe<RestoreClearingAgent>;
  restoreDriver?: Maybe<RestoreDriver>;
  restoreFuelStation?: Maybe<RestoreFuelStation>;
  restoreRegion?: Maybe<RestoreRegion>;
  restoreTrailer?: Maybe<RestoreTrailer>;
  restoreTransporter?: Maybe<RestoreTransporter>;
  restoreTruck?: Maybe<RestoreTruck>;
  restoreVessel?: Maybe<RestoreVessel>;
  syncDrivers?: Maybe<SyncDrivers>;
  syncTrailers?: Maybe<SyncTrailers>;
  syncTrucks?: Maybe<SyncTrucks>;
  updateCargoClient?: Maybe<UpdateCargoClient>;
  updateCargoClientStatus?: Maybe<UpdateCargoClientStatus>;
  updateClearingAgent?: Maybe<UpdateClearingAgent>;
  updateClearingAgentStatus?: Maybe<UpdateClearingAgentStatus>;
  updateDocument?: Maybe<UpdateDocument>;
  updateDriver?: Maybe<UpdateDriver>;
  updateDriverStatus?: Maybe<UpdateDriverStatus>;
  updateFuelStation?: Maybe<UpdateFuelStation>;
  updateFuelStationStatus?: Maybe<UpdateFuelStationStatus>;
  updateRegion?: Maybe<UpdateRegion>;
  updateTrailer?: Maybe<UpdateTrailer>;
  updateTrailerStatus?: Maybe<UpdateTrailerStatus>;
  updateTrailerType?: Maybe<UpdateTrailerType>;
  updateTransporter?: Maybe<UpdateTransporter>;
  updateTransporterStatus?: Maybe<UpdateTransporterStatus>;
  updateTruck?: Maybe<UpdateTruck>;
  updateTruckStatus?: Maybe<UpdateTruckStatus>;
  updateTruckType?: Maybe<UpdateTruckType>;
  updateVessel?: Maybe<UpdateVessel>;
  updateVesselStatus?: Maybe<UpdateVesselStatus>;
  uploadDocument?: Maybe<UploadDocument>;
};


export type RootMutationBulkCreateCargoClientsArgs = {
  cargoClientList?: InputMaybe<Array<InputMaybe<CreateCargoClientInput>>>;
};


export type RootMutationBulkCreateClearingAgentsArgs = {
  clearingAgentsList?: InputMaybe<Array<InputMaybe<CreateClearingAgentInput>>>;
};


export type RootMutationBulkCreateDriversArgs = {
  driverList?: InputMaybe<Array<InputMaybe<CreateDriverInput>>>;
  source: Source;
};


export type RootMutationBulkCreateTrailersArgs = {
  source: Source;
  trailerList?: InputMaybe<Array<InputMaybe<CreateTrailerInput>>>;
};


export type RootMutationBulkCreateTransportersArgs = {
  transporterList?: InputMaybe<Array<InputMaybe<CreateTransporterInput>>>;
};


export type RootMutationBulkCreateTrucksArgs = {
  source: Source;
  truckList?: InputMaybe<Array<InputMaybe<CreateTruckInput>>>;
};


export type RootMutationBulkCreateVesselArgs = {
  vesselList?: InputMaybe<Array<InputMaybe<CreateVesselInput>>>;
};


export type RootMutationBulkImportDriversArgs = {
  country: Scalars['String'];
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationBulkImportTrailersArgs = {
  country: Scalars['String'];
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationBulkImportTrucksArgs = {
  country: Scalars['String'];
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationBulkUpdateCargoClientsArgs = {
  cargoClientList?: InputMaybe<Array<InputMaybe<BulkUpdateCargoClientInput>>>;
};


export type RootMutationBulkUpdateClearingAgentArgs = {
  clearingAgentList?: InputMaybe<Array<InputMaybe<BulkUpdateClearingAgentInput>>>;
};


export type RootMutationBulkUpdateDriverArgs = {
  driverList?: InputMaybe<Array<InputMaybe<BulkUpdateDriverInput>>>;
};


export type RootMutationBulkUpdateFuelStationArgs = {
  fuelStationList?: InputMaybe<Array<InputMaybe<BulkUpdateFuelStationInput>>>;
};


export type RootMutationBulkUpdateTrailersArgs = {
  trailerList?: InputMaybe<Array<InputMaybe<BulkUpdateTrailerInput>>>;
};


export type RootMutationBulkUpdateTransporterArgs = {
  transporterList?: InputMaybe<Array<InputMaybe<BulkUpdateTransporterInput>>>;
};


export type RootMutationBulkUpdateTrucksArgs = {
  truckList?: InputMaybe<Array<InputMaybe<BulkUpdateTruckInput>>>;
};


export type RootMutationBulkUpdateVesselArgs = {
  vesselsList?: InputMaybe<Array<InputMaybe<BulkUpdateVesselInput>>>;
};


export type RootMutationBulkUploadDriversArgs = {
  country: Scalars['String'];
  file: Scalars['Upload'];
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationBulkUploadTrailersArgs = {
  country: Scalars['String'];
  file: Scalars['Upload'];
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationBulkUploadTrucksArgs = {
  country: Scalars['String'];
  file: Scalars['Upload'];
  source: Source;
  transporterId: Scalars['Int'];
};


export type RootMutationCreateCargoClientArgs = {
  cargoClientData?: InputMaybe<CreateCargoClientInput>;
};


export type RootMutationCreateClearingAgentArgs = {
  clearingAgentData?: InputMaybe<CreateClearingAgentInput>;
};


export type RootMutationCreateDriverArgs = {
  driverData?: InputMaybe<CreateDriverInput>;
  source: Source;
};


export type RootMutationCreateFuelStationArgs = {
  fuelStationData?: InputMaybe<CreateFuelStationInput>;
};


export type RootMutationCreateRegionArgs = {
  regionData?: InputMaybe<CreateRegionInput>;
};


export type RootMutationCreateTrailerArgs = {
  source: Source;
  trailerData?: InputMaybe<CreateTrailerInput>;
};


export type RootMutationCreateTrailerTypeArgs = {
  trailerTypeData?: InputMaybe<TrailerTypeInput>;
};


export type RootMutationCreateTransporterArgs = {
  transporterData?: InputMaybe<CreateTransporterInput>;
};


export type RootMutationCreateTruckArgs = {
  source: Source;
  truckData?: InputMaybe<CreateTruckInput>;
};


export type RootMutationCreateTruckTypeArgs = {
  truckTypeData?: InputMaybe<TruckTypeInput>;
};


export type RootMutationCreateVesselArgs = {
  vesselData?: InputMaybe<CreateVesselInput>;
};


export type RootMutationDeactivateRegionArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteCargoClientArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteClearingAgentArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteDocumentArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteDriverArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteFuelStationArgs = {
  name: Scalars['String'];
};


export type RootMutationDeleteTrailerArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteTrailerTypeArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteTransporterArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteTruckArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteTruckTypeArgs = {
  id: Scalars['Int'];
};


export type RootMutationDeleteVesselArgs = {
  vesselName: Scalars['String'];
};


export type RootMutationLinkDriversArgs = {
  driverList: Array<InputMaybe<DriverList>>;
};


export type RootMutationLinkTrailersArgs = {
  trailerList: Array<InputMaybe<TrailerList>>;
};


export type RootMutationMatchTrucksArgs = {
  truckList: Array<InputMaybe<TruckList>>;
};


export type RootMutationReleaseDriversArgs = {
  driverList: Array<InputMaybe<DriverReleaseList>>;
};


export type RootMutationReleaseTrailersArgs = {
  trailerList: Array<InputMaybe<TrailerReleaseList>>;
};


export type RootMutationReleaseTrucksArgs = {
  truckList: Array<InputMaybe<TruckReleaseList>>;
};


export type RootMutationRestoreCargoClientArgs = {
  id: Scalars['Int'];
};


export type RootMutationRestoreClearingAgentArgs = {
  id: Scalars['Int'];
};


export type RootMutationRestoreDriverArgs = {
  id: Scalars['Int'];
};


export type RootMutationRestoreFuelStationArgs = {
  name: Scalars['String'];
};


export type RootMutationRestoreRegionArgs = {
  id: Scalars['Int'];
};


export type RootMutationRestoreTrailerArgs = {
  id: Scalars['Int'];
};


export type RootMutationRestoreTransporterArgs = {
  id: Scalars['Int'];
};


export type RootMutationRestoreTruckArgs = {
  id: Scalars['Int'];
};


export type RootMutationRestoreVesselArgs = {
  vesselName: Scalars['String'];
};


export type RootMutationUpdateCargoClientArgs = {
  cargoClientData?: InputMaybe<UpdateCargoClientInput>;
  id: Scalars['Int'];
};


export type RootMutationUpdateCargoClientStatusArgs = {
  activate: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type RootMutationUpdateClearingAgentArgs = {
  clearingAgentData?: InputMaybe<UpdateClearingAgentInput>;
  id: Scalars['Int'];
};


export type RootMutationUpdateClearingAgentStatusArgs = {
  activate: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type RootMutationUpdateDocumentArgs = {
  documentData?: InputMaybe<UpdateDocumentInput>;
  documentTypeId?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['Upload']>;
  id: Scalars['Int'];
};


export type RootMutationUpdateDriverArgs = {
  driverData?: InputMaybe<UpdateDriverInput>;
  id: Scalars['Int'];
};


export type RootMutationUpdateDriverStatusArgs = {
  activate: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type RootMutationUpdateFuelStationArgs = {
  fuelStationData?: InputMaybe<UpdateFuelStationInput>;
  name: Scalars['String'];
};


export type RootMutationUpdateFuelStationStatusArgs = {
  activate: Scalars['Boolean'];
  fuelStationName: Scalars['String'];
};


export type RootMutationUpdateRegionArgs = {
  id: Scalars['Int'];
  regionData?: InputMaybe<UpdateRegionInput>;
};


export type RootMutationUpdateTrailerArgs = {
  id: Scalars['Int'];
  trailerData?: InputMaybe<UpdateTrailerInput>;
};


export type RootMutationUpdateTrailerStatusArgs = {
  activate: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type RootMutationUpdateTrailerTypeArgs = {
  id: Scalars['Int'];
  trailerTypeData?: InputMaybe<TrailerTypeInput>;
};


export type RootMutationUpdateTransporterArgs = {
  id: Scalars['Int'];
  transporterData?: InputMaybe<UpdateTransporterInput>;
};


export type RootMutationUpdateTransporterStatusArgs = {
  activate: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type RootMutationUpdateTruckArgs = {
  id: Scalars['Int'];
  truckData?: InputMaybe<UpdateTruckInput>;
};


export type RootMutationUpdateTruckStatusArgs = {
  activate: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type RootMutationUpdateTruckTypeArgs = {
  id: Scalars['Int'];
  truckTypeData?: InputMaybe<TruckTypeInput>;
};


export type RootMutationUpdateVesselArgs = {
  vesselData?: InputMaybe<UpdateVesselInput>;
  vesselName: Scalars['String'];
};


export type RootMutationUpdateVesselStatusArgs = {
  activate: Scalars['Boolean'];
  vesselName: Scalars['String'];
};


export type RootMutationUploadDocumentArgs = {
  documentData?: InputMaybe<CreateDocumentInput>;
  documentTypeId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type RootQuery = {
  __typename?: 'RootQuery';
  accountManager?: Maybe<AccountManager>;
  accountManagers?: Maybe<AccountManagerConnection>;
  assetIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  cargoClient?: Maybe<CargoClient>;
  cargoClients?: Maybe<CargoClientConnection>;
  clearingAgent?: Maybe<ClearingAgent>;
  clearingAgents?: Maybe<ClearingAgentConnection>;
  document?: Maybe<Document>;
  documents?: Maybe<DocumentConnectionsConnection>;
  documentType?: Maybe<DocumentType>;
  documentTypes?: Maybe<DocumentTypeConnection>;
  driver?: Maybe<Driver>;
  drivers?: Maybe<DriverConnectionsConnection>;
  entities?: Maybe<EntityConnection>;
  entity?: Maybe<Entity>;
  fuelStation?: Maybe<FuelStation>;
  fuelStations?: Maybe<FuelStationConnection>;
  isDriverAvailable?: Maybe<Array<Maybe<DriverAvailability>>>;
  isTrailerAvailable?: Maybe<Array<Maybe<TrailerAvailability>>>;
  isTransporterValid?: Maybe<Array<Maybe<TransporterValidity>>>;
  isTruckAvailable?: Maybe<Array<Maybe<TruckAvailability>>>;
  node?: Maybe<Node>;
  region?: Maybe<Region>;
  regions?: Maybe<RegionConnection>;
  trailer?: Maybe<Trailer>;
  trailers?: Maybe<TrailerConnectionsConnection>;
  trailerType?: Maybe<TrailerTypes>;
  trailerTypes?: Maybe<TrailerTypesConnection>;
  transporter?: Maybe<Transporter>;
  transporters?: Maybe<TransporterConnection>;
  truck?: Maybe<Truck>;
  trucks?: Maybe<TruckConnectionsConnection>;
  truckType?: Maybe<TruckTypes>;
  truckTypes?: Maybe<TruckTypeConnection>;
  vessel?: Maybe<Vessel>;
  vessels?: Maybe<VesselConnection>;
};


export type RootQueryAccountManagerArgs = {
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  workMobile?: InputMaybe<Scalars['String']>;
};


export type RootQueryAccountManagersArgs = {
  accountManagerIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  accountManagersEmailList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  accountManagersMobileList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  accountManagersNameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  accountManagersWorkMobileList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<AccountManagerSortEnum>>>;
};


export type RootQueryAssetIdsArgs = {
  assetType: AssetType;
  transporterId: Scalars['Int'];
};


export type RootQueryCargoClientArgs = {
  companyRegisteredName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type RootQueryCargoClientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  companyRegisteredNameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  isReceiver?: InputMaybe<Scalars['Boolean']>;
  isShipper?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<RegionEnum>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<CargoClientSortEnum>>>;
};


export type RootQueryClearingAgentArgs = {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type RootQueryClearingAgentsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  clearingAgentsCodeList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clearingAgentsNameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  loriApproved?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<RegionEnum>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<ClearingAgentSortEnum>>>;
};


export type RootQueryDocumentArgs = {
  moveId?: InputMaybe<Scalars['Int']>;
};


export type RootQueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  moveIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sort?: InputMaybe<Array<InputMaybe<DocumentSortEnum>>>;
};


export type RootQueryDocumentTypeArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type RootQueryDocumentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  documentIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<RegionEnum>;
  sort?: InputMaybe<Array<InputMaybe<DocumentTypeSortEnum>>>;
};


export type RootQueryDriverArgs = {
  id?: InputMaybe<Scalars['Int']>;
  idNumber?: InputMaybe<Scalars['String']>;
  moveId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['String']>;
};


export type RootQueryDriversArgs = {
  active?: InputMaybe<ActiveEnum>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  driverIdNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  idNumberList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last?: InputMaybe<Scalars['Int']>;
  lroIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moveIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  region?: InputMaybe<RegionEnum>;
  search?: InputMaybe<Scalars['String']>;
  serialList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort?: InputMaybe<Array<InputMaybe<DriverSortEnum>>>;
  source?: InputMaybe<Source>;
  status?: InputMaybe<LinkEnum>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  woids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type RootQueryEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  companyEmailList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  companyMobileList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  companyNameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<EntitySortEnum>>>;
};


export type RootQueryEntityArgs = {
  companyEmail?: InputMaybe<Scalars['String']>;
  companyMobile?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type RootQueryFuelStationArgs = {
  code?: InputMaybe<Scalars['String']>;
  geoId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
};


export type RootQueryFuelStationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  codeList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  geoIdList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last?: InputMaybe<Scalars['Int']>;
  nameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ownerIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<FuelStationSortEnum>>>;
};


export type RootQueryIsDriverAvailableArgs = {
  driverIdNumberList: Array<InputMaybe<Scalars['String']>>;
};


export type RootQueryIsTrailerAvailableArgs = {
  trailerSerialList: Array<InputMaybe<Scalars['String']>>;
};


export type RootQueryIsTransporterValidArgs = {
  transporterIdList: Array<InputMaybe<Scalars['Int']>>;
};


export type RootQueryIsTruckAvailableArgs = {
  truckSerialList: Array<InputMaybe<Scalars['String']>>;
};


export type RootQueryNodeArgs = {
  id: Scalars['ID'];
};


export type RootQueryRegionArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type RootQueryRegionsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  regionCodeList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  regionIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<RegionSortEnum>>>;
};


export type RootQueryTrailerArgs = {
  id?: InputMaybe<Scalars['Int']>;
  moveId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
};


export type RootQueryTrailersArgs = {
  active?: InputMaybe<ActiveEnum>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lroIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moveIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  region?: InputMaybe<RegionEnum>;
  search?: InputMaybe<Scalars['String']>;
  serialList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort?: InputMaybe<Array<InputMaybe<TrailerSortEnum>>>;
  source?: InputMaybe<Source>;
  status?: InputMaybe<LinkEnum>;
  trailerId?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  trailerReg?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  trailerRegList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  woids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type RootQueryTrailerTypeArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type RootQueryTrailerTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  nameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort?: InputMaybe<Array<InputMaybe<TrailerTypesSortEnum>>>;
  trailerTypesIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type RootQueryTransporterArgs = {
  companyEmail?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type RootQueryTransportersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<RegionEnum>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<TransporterSortEnum>>>;
  transporterList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type RootQueryTruckArgs = {
  id?: InputMaybe<Scalars['Int']>;
  idNumber?: InputMaybe<Scalars['String']>;
  moveId?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  truckReg?: InputMaybe<Scalars['String']>;
};


export type RootQueryTrucksArgs = {
  active?: InputMaybe<ActiveEnum>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  driverIdNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lroIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  moveIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  region?: InputMaybe<RegionEnum>;
  search?: InputMaybe<Scalars['String']>;
  serialList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort?: InputMaybe<Array<InputMaybe<TruckSortEnum>>>;
  source?: InputMaybe<Source>;
  status?: InputMaybe<MatchEnum>;
  trailerReg?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  truckRegList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  woids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type RootQueryTruckTypeArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type RootQueryTruckTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  nameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort?: InputMaybe<Array<InputMaybe<TruckTypesSortEnum>>>;
  truckTypeIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type RootQueryVesselArgs = {
  imo?: InputMaybe<Scalars['String']>;
  mmsi?: InputMaybe<Scalars['Int']>;
  vesselName?: InputMaybe<Scalars['String']>;
};


export type RootQueryVesselsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  imoList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last?: InputMaybe<Scalars['Int']>;
  mmsiList?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Array<InputMaybe<VesselSortEnum>>>;
  vesselNameList?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum Source {
  Lfa = 'LFA',
  Lia = 'LIA',
  Lta = 'LTA'
}

export type SyncDrivers = {
  __typename?: 'SyncDrivers';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type SyncTrailers = {
  __typename?: 'SyncTrailers';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type SyncTrucks = {
  __typename?: 'SyncTrucks';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type Trailer = TrailerNode & {
  __typename?: 'Trailer';
  active?: Maybe<Scalars['Boolean']>;
  cargoCapacity?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Document>;
  id: Scalars['ID'];
  linkedTo?: Maybe<Scalars['String']>;
  loadingType?: Maybe<Scalars['String']>;
  onjourneyTruck?: Maybe<Truck>;
  onjourneyTruckId?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  trailerOnBoarded?: Maybe<Scalars['Boolean']>;
  trailerReg?: Maybe<Scalars['String']>;
  trailerSubType?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  trailerTypeId?: Maybe<Scalars['Int']>;
  trailerTypes?: Maybe<TrailerTypes>;
  transporter?: Maybe<Transporter>;
  transporterId?: Maybe<Scalars['Int']>;
  truck?: Maybe<Truck>;
  truckId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vettedBy?: Maybe<Scalars['String']>;
  vettedDate?: Maybe<Scalars['DateTime']>;
  yom?: Maybe<Scalars['DateTime']>;
};

export type TrailerAvailability = {
  __typename?: 'TrailerAvailability';
  isAvailable?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  trailerId?: Maybe<Scalars['Int']>;
  trailerTypeId?: Maybe<Scalars['Int']>;
};

export type TrailerConnection = {
  __typename?: 'TrailerConnection';
  edges: Array<Maybe<TrailerEdge>>;
  pageInfo: PageInfo;
};

export type TrailerConnectionsConnection = {
  __typename?: 'TrailerConnectionsConnection';
  edges: Array<Maybe<TrailerConnectionsEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TrailerConnectionsEdge = {
  __typename?: 'TrailerConnectionsEdge';
  cursor: Scalars['String'];
  node?: Maybe<Trailer>;
  other?: Maybe<Scalars['String']>;
};

export type TrailerEdge = {
  __typename?: 'TrailerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Trailer>;
};

export type TrailerLinkingResults = {
  __typename?: 'TrailerLinkingResults';
  message?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  trailerId?: Maybe<Scalars['Int']>;
  truckSerial?: Maybe<Scalars['String']>;
};

export type TrailerList = {
  trailerSerial: Scalars['String'];
  truckSerial: Scalars['String'];
};

export type TrailerNode = {
  id: Scalars['ID'];
};

export type TrailerReleaseList = {
  nextStage?: InputMaybe<ReleaseStageEnum>;
  trailerSerial: Scalars['String'];
};

export enum TrailerSortEnum {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CargoCapacityAsc = 'CARGO_CAPACITY_ASC',
  CargoCapacityDesc = 'CARGO_CAPACITY_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LinkedToAsc = 'LINKED_TO_ASC',
  LinkedToDesc = 'LINKED_TO_DESC',
  LoadingTypeAsc = 'LOADING_TYPE_ASC',
  LoadingTypeDesc = 'LOADING_TYPE_DESC',
  OnjourneyTruckIdAsc = 'ONJOURNEY_TRUCK_ID_ASC',
  OnjourneyTruckIdDesc = 'ONJOURNEY_TRUCK_ID_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  SerialAsc = 'SERIAL_ASC',
  SerialDesc = 'SERIAL_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TrailerOnBoardedAsc = 'TRAILER_ON_BOARDED_ASC',
  TrailerOnBoardedDesc = 'TRAILER_ON_BOARDED_DESC',
  TrailerRegAsc = 'TRAILER_REG_ASC',
  TrailerRegDesc = 'TRAILER_REG_DESC',
  TrailerSubTypeAsc = 'TRAILER_SUB_TYPE_ASC',
  TrailerSubTypeDesc = 'TRAILER_SUB_TYPE_DESC',
  TrailerTypeAsc = 'TRAILER_TYPE_ASC',
  TrailerTypeDesc = 'TRAILER_TYPE_DESC',
  TrailerTypeIdAsc = 'TRAILER_TYPE_ID_ASC',
  TrailerTypeIdDesc = 'TRAILER_TYPE_ID_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TruckIdAsc = 'TRUCK_ID_ASC',
  TruckIdDesc = 'TRUCK_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VettedByAsc = 'VETTED_BY_ASC',
  VettedByDesc = 'VETTED_BY_DESC',
  VettedDateAsc = 'VETTED_DATE_ASC',
  VettedDateDesc = 'VETTED_DATE_DESC',
  YomAsc = 'YOM_ASC',
  YomDesc = 'YOM_DESC'
}

export type TrailerTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TrailerTypeNode = {
  id: Scalars['ID'];
};

export type TrailerTypes = TrailerTypeNode & {
  __typename?: 'TrailerTypes';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Trailer>;
};

export type TrailerTypesConnection = {
  __typename?: 'TrailerTypesConnection';
  edges: Array<Maybe<TrailerTypesEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TrailerTypesEdge = {
  __typename?: 'TrailerTypesEdge';
  cursor: Scalars['String'];
  node?: Maybe<TrailerTypes>;
  other?: Maybe<Scalars['String']>;
};

export enum TrailerTypesSortEnum {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type Transporter = TransporterNode & {
  __typename?: 'Transporter';
  accountManager?: Maybe<Scalars['String']>;
  accountManagerEmail?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  bankAccountCurency?: Maybe<Scalars['String']>;
  bankAccountName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankNameBranch?: Maybe<Scalars['String']>;
  bankSwiftCode?: Maybe<Scalars['String']>;
  benefiaryAddress?: Maybe<Scalars['String']>;
  beneficiaryBusinessName?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  claimStatus?: Maybe<Scalars['Boolean']>;
  companyEmail?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['String']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonPhone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  directorName?: Maybe<Scalars['String']>;
  documents?: Maybe<DocumentConnection>;
  drivers?: Maybe<DriverConnection>;
  gitInsurance?: Maybe<Scalars['String']>;
  gitInsuranceExpiryDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  kraIn?: Maybe<Scalars['String']>;
  kraTcc?: Maybe<Scalars['String']>;
  kwatosCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  physicalAddress?: Maybe<Scalars['String']>;
  podAssignment?: Maybe<Scalars['String']>;
  portSticker?: Maybe<Scalars['String']>;
  proofOfDirectorship?: Maybe<Scalars['String']>;
  qboCustomerIdLocal?: Maybe<Scalars['String']>;
  qboCustomerIdUsd?: Maybe<Scalars['String']>;
  qboVendorIdLocal?: Maybe<Scalars['String']>;
  qboVendorIdUsd?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  registrationCertificate?: Maybe<Scalars['String']>;
  roadWorthinessCertificate?: Maybe<Scalars['String']>;
  trailers?: Maybe<TrailerConnection>;
  trakitEntityId?: Maybe<Scalars['Int']>;
  transporterPaymentPeriod?: Maybe<Scalars['Float']>;
  transporterPaymentTerms?: Maybe<Scalars['Float']>;
  trucks?: Maybe<TruckConnection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type TransporterDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TransporterDriversArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TransporterTrailersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TransporterTrucksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TransporterConnection = {
  __typename?: 'TransporterConnection';
  edges: Array<Maybe<TransporterEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TransporterEdge = {
  __typename?: 'TransporterEdge';
  cursor: Scalars['String'];
  node?: Maybe<Transporter>;
  other?: Maybe<Scalars['String']>;
};

export type TransporterNode = {
  id: Scalars['ID'];
};

export enum TransporterSortEnum {
  AccountManagerAsc = 'ACCOUNT_MANAGER_ASC',
  AccountManagerDesc = 'ACCOUNT_MANAGER_DESC',
  AccountManagerEmailAsc = 'ACCOUNT_MANAGER_EMAIL_ASC',
  AccountManagerEmailDesc = 'ACCOUNT_MANAGER_EMAIL_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  BankAccountCurencyAsc = 'BANK_ACCOUNT_CURENCY_ASC',
  BankAccountCurencyDesc = 'BANK_ACCOUNT_CURENCY_DESC',
  BankAccountNameAsc = 'BANK_ACCOUNT_NAME_ASC',
  BankAccountNameDesc = 'BANK_ACCOUNT_NAME_DESC',
  BankAccountNumberAsc = 'BANK_ACCOUNT_NUMBER_ASC',
  BankAccountNumberDesc = 'BANK_ACCOUNT_NUMBER_DESC',
  BankAddressAsc = 'BANK_ADDRESS_ASC',
  BankAddressDesc = 'BANK_ADDRESS_DESC',
  BankCodeAsc = 'BANK_CODE_ASC',
  BankCodeDesc = 'BANK_CODE_DESC',
  BankNameAsc = 'BANK_NAME_ASC',
  BankNameBranchAsc = 'BANK_NAME_BRANCH_ASC',
  BankNameBranchDesc = 'BANK_NAME_BRANCH_DESC',
  BankNameDesc = 'BANK_NAME_DESC',
  BankSwiftCodeAsc = 'BANK_SWIFT_CODE_ASC',
  BankSwiftCodeDesc = 'BANK_SWIFT_CODE_DESC',
  BenefiaryAddressAsc = 'BENEFIARY_ADDRESS_ASC',
  BenefiaryAddressDesc = 'BENEFIARY_ADDRESS_DESC',
  BeneficiaryBusinessNameAsc = 'BENEFICIARY_BUSINESS_NAME_ASC',
  BeneficiaryBusinessNameDesc = 'BENEFICIARY_BUSINESS_NAME_DESC',
  BillingAddressAsc = 'BILLING_ADDRESS_ASC',
  BillingAddressDesc = 'BILLING_ADDRESS_DESC',
  ClaimStatusAsc = 'CLAIM_STATUS_ASC',
  ClaimStatusDesc = 'CLAIM_STATUS_DESC',
  CompanyEmailAsc = 'COMPANY_EMAIL_ASC',
  CompanyEmailDesc = 'COMPANY_EMAIL_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  ContactPersonAsc = 'CONTACT_PERSON_ASC',
  ContactPersonDesc = 'CONTACT_PERSON_DESC',
  ContactPersonEmailAsc = 'CONTACT_PERSON_EMAIL_ASC',
  ContactPersonEmailDesc = 'CONTACT_PERSON_EMAIL_DESC',
  ContactPersonPhoneAsc = 'CONTACT_PERSON_PHONE_ASC',
  ContactPersonPhoneDesc = 'CONTACT_PERSON_PHONE_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  DirectorNameAsc = 'DIRECTOR_NAME_ASC',
  DirectorNameDesc = 'DIRECTOR_NAME_DESC',
  GitInsuranceAsc = 'GIT_INSURANCE_ASC',
  GitInsuranceDesc = 'GIT_INSURANCE_DESC',
  GitInsuranceExpiryDateAsc = 'GIT_INSURANCE_EXPIRY_DATE_ASC',
  GitInsuranceExpiryDateDesc = 'GIT_INSURANCE_EXPIRY_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsBlacklistedAsc = 'IS_BLACKLISTED_ASC',
  IsBlacklistedDesc = 'IS_BLACKLISTED_DESC',
  KraInAsc = 'KRA_IN_ASC',
  KraInDesc = 'KRA_IN_DESC',
  KraTccAsc = 'KRA_TCC_ASC',
  KraTccDesc = 'KRA_TCC_DESC',
  KwatosCodeAsc = 'KWATOS_CODE_ASC',
  KwatosCodeDesc = 'KWATOS_CODE_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PhysicalAddressAsc = 'PHYSICAL_ADDRESS_ASC',
  PhysicalAddressDesc = 'PHYSICAL_ADDRESS_DESC',
  PodAssignmentAsc = 'POD_ASSIGNMENT_ASC',
  PodAssignmentDesc = 'POD_ASSIGNMENT_DESC',
  PortStickerAsc = 'PORT_STICKER_ASC',
  PortStickerDesc = 'PORT_STICKER_DESC',
  ProofOfDirectorshipAsc = 'PROOF_OF_DIRECTORSHIP_ASC',
  ProofOfDirectorshipDesc = 'PROOF_OF_DIRECTORSHIP_DESC',
  QboCustomerIdLocalAsc = 'QBO_CUSTOMER_ID_LOCAL_ASC',
  QboCustomerIdLocalDesc = 'QBO_CUSTOMER_ID_LOCAL_DESC',
  QboCustomerIdUsdAsc = 'QBO_CUSTOMER_ID_USD_ASC',
  QboCustomerIdUsdDesc = 'QBO_CUSTOMER_ID_USD_DESC',
  QboVendorIdLocalAsc = 'QBO_VENDOR_ID_LOCAL_ASC',
  QboVendorIdLocalDesc = 'QBO_VENDOR_ID_LOCAL_DESC',
  QboVendorIdUsdAsc = 'QBO_VENDOR_ID_USD_ASC',
  QboVendorIdUsdDesc = 'QBO_VENDOR_ID_USD_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  RegistrationCertificateAsc = 'REGISTRATION_CERTIFICATE_ASC',
  RegistrationCertificateDesc = 'REGISTRATION_CERTIFICATE_DESC',
  RoadWorthinessCertificateAsc = 'ROAD_WORTHINESS_CERTIFICATE_ASC',
  RoadWorthinessCertificateDesc = 'ROAD_WORTHINESS_CERTIFICATE_DESC',
  TrakitEntityIdAsc = 'TRAKIT_ENTITY_ID_ASC',
  TrakitEntityIdDesc = 'TRAKIT_ENTITY_ID_DESC',
  TransporterPaymentPeriodAsc = 'TRANSPORTER_PAYMENT_PERIOD_ASC',
  TransporterPaymentPeriodDesc = 'TRANSPORTER_PAYMENT_PERIOD_DESC',
  TransporterPaymentTermsAsc = 'TRANSPORTER_PAYMENT_TERMS_ASC',
  TransporterPaymentTermsDesc = 'TRANSPORTER_PAYMENT_TERMS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type TransporterValidity = {
  __typename?: 'TransporterValidity';
  accountManager?: Maybe<Scalars['String']>;
  accountManagerEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterPaymentTerms?: Maybe<Scalars['Float']>;
};

export type Truck = TruckNode & {
  __typename?: 'Truck';
  active?: Maybe<Scalars['Boolean']>;
  cargoCapacity?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Document>;
  driver?: Maybe<Driver>;
  ectsExpiry?: Maybe<Scalars['DateTime']>;
  ectsLicense?: Maybe<Scalars['String']>;
  ectsProvider?: Maybe<Scalars['String']>;
  gitInsurance?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  insuranceExpiry?: Maybe<Scalars['DateTime']>;
  insuranceProvider?: Maybe<Scalars['String']>;
  journeyHours?: Maybe<Scalars['Float']>;
  kwatos?: Maybe<Scalars['String']>;
  linkedTo?: Maybe<Scalars['String']>;
  loadingType?: Maybe<Scalars['String']>;
  loriCaptiveScheme?: Maybe<Scalars['String']>;
  loriInsuranceScheme?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  match?: Maybe<Scalars['DateTime']>;
  model?: Maybe<Scalars['String']>;
  moveId?: Maybe<Scalars['Int']>;
  ntsaInspectionExpiry?: Maybe<Scalars['DateTime']>;
  onBoardingStatus?: Maybe<Scalars['String']>;
  onjourneyDriver?: Maybe<Driver>;
  onjourneyMoveId?: Maybe<Scalars['Int']>;
  onjourneyTrailer?: Maybe<Trailer>;
  portPass?: Maybe<Scalars['String']>;
  portPassExpiry?: Maybe<Scalars['DateTime']>;
  region?: Maybe<Scalars['String']>;
  registeredAtIcd?: Maybe<Scalars['String']>;
  roadWorthinessCertificate?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tglTransitPassComesa?: Maybe<Scalars['String']>;
  tglTransitPassExpiry?: Maybe<Scalars['String']>;
  trailer?: Maybe<Trailer>;
  trailerReg?: Maybe<Scalars['String']>;
  transitPass?: Maybe<Scalars['String']>;
  transporter?: Maybe<Transporter>;
  transporterId?: Maybe<Scalars['Int']>;
  truckReg?: Maybe<Scalars['String']>;
  truckType?: Maybe<TruckTypes>;
  truckTypeId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vettedBy?: Maybe<Scalars['String']>;
  vettedDate?: Maybe<Scalars['DateTime']>;
  yom?: Maybe<Scalars['DateTime']>;
};

export type TruckAvailability = {
  __typename?: 'TruckAvailability';
  isAvailable?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  truckId?: Maybe<Scalars['Int']>;
};

export type TruckConnection = {
  __typename?: 'TruckConnection';
  edges: Array<Maybe<TruckEdge>>;
  pageInfo: PageInfo;
};

export type TruckConnectionsConnection = {
  __typename?: 'TruckConnectionsConnection';
  edges: Array<Maybe<TruckConnectionsEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TruckConnectionsEdge = {
  __typename?: 'TruckConnectionsEdge';
  cursor: Scalars['String'];
  node?: Maybe<Truck>;
  other?: Maybe<Scalars['String']>;
};

export type TruckEdge = {
  __typename?: 'TruckEdge';
  cursor: Scalars['String'];
  node?: Maybe<Truck>;
};

export type TruckList = {
  moveId: Scalars['Int'];
  truckSerial: Scalars['String'];
};

export type TruckNode = {
  id: Scalars['ID'];
};

export type TruckReleaseList = {
  nextStage?: InputMaybe<ReleaseStageEnum>;
  truckSerial: Scalars['String'];
};

export enum TruckSortEnum {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CargoCapacityAsc = 'CARGO_CAPACITY_ASC',
  CargoCapacityDesc = 'CARGO_CAPACITY_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EctsExpiryAsc = 'ECTS_EXPIRY_ASC',
  EctsExpiryDesc = 'ECTS_EXPIRY_DESC',
  EctsLicenseAsc = 'ECTS_LICENSE_ASC',
  EctsLicenseDesc = 'ECTS_LICENSE_DESC',
  EctsProviderAsc = 'ECTS_PROVIDER_ASC',
  EctsProviderDesc = 'ECTS_PROVIDER_DESC',
  GitInsuranceAsc = 'GIT_INSURANCE_ASC',
  GitInsuranceDesc = 'GIT_INSURANCE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InsuranceExpiryAsc = 'INSURANCE_EXPIRY_ASC',
  InsuranceExpiryDesc = 'INSURANCE_EXPIRY_DESC',
  InsuranceProviderAsc = 'INSURANCE_PROVIDER_ASC',
  InsuranceProviderDesc = 'INSURANCE_PROVIDER_DESC',
  JourneyHoursAsc = 'JOURNEY_HOURS_ASC',
  JourneyHoursDesc = 'JOURNEY_HOURS_DESC',
  KwatosAsc = 'KWATOS_ASC',
  KwatosDesc = 'KWATOS_DESC',
  LinkedToAsc = 'LINKED_TO_ASC',
  LinkedToDesc = 'LINKED_TO_DESC',
  LoadingTypeAsc = 'LOADING_TYPE_ASC',
  LoadingTypeDesc = 'LOADING_TYPE_DESC',
  LoriCaptiveSchemeAsc = 'LORI_CAPTIVE_SCHEME_ASC',
  LoriCaptiveSchemeDesc = 'LORI_CAPTIVE_SCHEME_DESC',
  LoriInsuranceSchemeAsc = 'LORI_INSURANCE_SCHEME_ASC',
  LoriInsuranceSchemeDesc = 'LORI_INSURANCE_SCHEME_DESC',
  MakeAsc = 'MAKE_ASC',
  MakeDesc = 'MAKE_DESC',
  MatchAsc = 'MATCH_ASC',
  MatchDesc = 'MATCH_DESC',
  ModelAsc = 'MODEL_ASC',
  ModelDesc = 'MODEL_DESC',
  MoveIdAsc = 'MOVE_ID_ASC',
  MoveIdDesc = 'MOVE_ID_DESC',
  NtsaInspectionExpiryAsc = 'NTSA_INSPECTION_EXPIRY_ASC',
  NtsaInspectionExpiryDesc = 'NTSA_INSPECTION_EXPIRY_DESC',
  OnBoardingStatusAsc = 'ON_BOARDING_STATUS_ASC',
  OnBoardingStatusDesc = 'ON_BOARDING_STATUS_DESC',
  OnjourneyMoveIdAsc = 'ONJOURNEY_MOVE_ID_ASC',
  OnjourneyMoveIdDesc = 'ONJOURNEY_MOVE_ID_DESC',
  PortPassAsc = 'PORT_PASS_ASC',
  PortPassDesc = 'PORT_PASS_DESC',
  PortPassExpiryAsc = 'PORT_PASS_EXPIRY_ASC',
  PortPassExpiryDesc = 'PORT_PASS_EXPIRY_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  RegisteredAtIcdAsc = 'REGISTERED_AT_ICD_ASC',
  RegisteredAtIcdDesc = 'REGISTERED_AT_ICD_DESC',
  RoadWorthinessCertificateAsc = 'ROAD_WORTHINESS_CERTIFICATE_ASC',
  RoadWorthinessCertificateDesc = 'ROAD_WORTHINESS_CERTIFICATE_DESC',
  SerialAsc = 'SERIAL_ASC',
  SerialDesc = 'SERIAL_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TglTransitPassComesaAsc = 'TGL_TRANSIT_PASS_COMESA_ASC',
  TglTransitPassComesaDesc = 'TGL_TRANSIT_PASS_COMESA_DESC',
  TglTransitPassExpiryAsc = 'TGL_TRANSIT_PASS_EXPIRY_ASC',
  TglTransitPassExpiryDesc = 'TGL_TRANSIT_PASS_EXPIRY_DESC',
  TrailerRegAsc = 'TRAILER_REG_ASC',
  TrailerRegDesc = 'TRAILER_REG_DESC',
  TransitPassAsc = 'TRANSIT_PASS_ASC',
  TransitPassDesc = 'TRANSIT_PASS_DESC',
  TransporterIdAsc = 'TRANSPORTER_ID_ASC',
  TransporterIdDesc = 'TRANSPORTER_ID_DESC',
  TruckRegAsc = 'TRUCK_REG_ASC',
  TruckRegDesc = 'TRUCK_REG_DESC',
  TruckTypeIdAsc = 'TRUCK_TYPE_ID_ASC',
  TruckTypeIdDesc = 'TRUCK_TYPE_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VettedByAsc = 'VETTED_BY_ASC',
  VettedByDesc = 'VETTED_BY_DESC',
  VettedDateAsc = 'VETTED_DATE_ASC',
  VettedDateDesc = 'VETTED_DATE_DESC',
  YomAsc = 'YOM_ASC',
  YomDesc = 'YOM_DESC'
}

export type TruckTypeConnection = {
  __typename?: 'TruckTypeConnection';
  edges: Array<Maybe<TruckTypeEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type TruckTypeEdge = {
  __typename?: 'TruckTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<TruckTypes>;
  other?: Maybe<Scalars['String']>;
};

export type TruckTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TruckTypeNode = {
  id: Scalars['ID'];
};

export type TruckTypes = TruckTypeNode & {
  __typename?: 'TruckTypes';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  truck?: Maybe<Truck>;
};

export enum TruckTypesSortEnum {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type UpdateCargoClient = {
  __typename?: 'UpdateCargoClient';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateCargoClientInput = {
  accountManager?: InputMaybe<Scalars['String']>;
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  agreedPaymentRate?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['String']>;
  auditorsName?: InputMaybe<Scalars['String']>;
  auditorsPhone?: InputMaybe<Scalars['String']>;
  auditorsPostalAddress?: InputMaybe<Scalars['String']>;
  bankAccountName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountType?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankBranch?: InputMaybe<Scalars['String']>;
  bankNumber?: InputMaybe<Scalars['Int']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryBusinessName?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  c2KraDocument?: InputMaybe<Scalars['Boolean']>;
  clientVendorForm?: InputMaybe<Scalars['Upload']>;
  companyBbbeeCertificate?: InputMaybe<Scalars['Upload']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyDateEstablished?: InputMaybe<Scalars['String']>;
  companyDirectorsDocument?: InputMaybe<Scalars['Upload']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyLetterhead?: InputMaybe<Scalars['Upload']>;
  companyLetterOfGoodStanding?: InputMaybe<Scalars['Upload']>;
  companyLogo?: InputMaybe<Scalars['Upload']>;
  companyPhone?: InputMaybe<Scalars['String']>;
  companyProofOfBanking?: InputMaybe<Scalars['Upload']>;
  companyRegisteredName?: InputMaybe<Scalars['String']>;
  companyRegistrationNumber?: InputMaybe<Scalars['String']>;
  companyStatutoryDocument?: InputMaybe<Scalars['Upload']>;
  companyTcc?: InputMaybe<Scalars['String']>;
  companyTradingName?: InputMaybe<Scalars['String']>;
  companyVatRegistrationNumber?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonName?: InputMaybe<Scalars['String']>;
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  containerizedCargo?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  creditLimitPeriod?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  directorName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  formOfEnterprise?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['String']>;
  isBlacklisted?: InputMaybe<Scalars['String']>;
  isReceiver?: InputMaybe<Scalars['Boolean']>;
  isShipper?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  paymentCurrency?: InputMaybe<Scalars['String']>;
  paymentMode?: InputMaybe<Scalars['String']>;
  paymentPeriod?: InputMaybe<Scalars['String']>;
  paymentRateAmount?: InputMaybe<Scalars['String']>;
  paymentRateType?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  podAssignment?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  preferredCreditLimit?: InputMaybe<Scalars['String']>;
  preferredPaymentMode?: InputMaybe<Scalars['String']>;
  proofOfDelivery?: InputMaybe<Scalars['String']>;
  qboCustomerIdLocal?: InputMaybe<Scalars['String']>;
  qboCustomerIdUsd?: InputMaybe<Scalars['String']>;
  qboVendorIdLocal?: InputMaybe<Scalars['String']>;
  qboVendorIdUsd?: InputMaybe<Scalars['String']>;
  t1ExitNote?: InputMaybe<Scalars['Boolean']>;
  trakitEntityId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  weighbridgeSlips?: InputMaybe<Scalars['String']>;
};

export type UpdateCargoClientStatus = {
  __typename?: 'UpdateCargoClientStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateClearingAgent = {
  __typename?: 'UpdateClearingAgent';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateClearingAgentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  certificateOfIncorporation?: InputMaybe<Scalars['Upload']>;
  code?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdOn?: InputMaybe<Scalars['String']>;
  docs?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  loriApproved?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
  vatCertificate?: InputMaybe<Scalars['Upload']>;
};

export type UpdateClearingAgentStatus = {
  __typename?: 'UpdateClearingAgentStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateDocument = {
  __typename?: 'UpdateDocument';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateDocumentInput = {
  cargoClientId?: InputMaybe<Scalars['Int']>;
  clearingAgentId?: InputMaybe<Scalars['Int']>;
  driverId?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  trailerId?: InputMaybe<Scalars['Int']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckId?: InputMaybe<Scalars['Int']>;
  vesselName?: InputMaybe<Scalars['String']>;
};

export type UpdateDriver = {
  __typename?: 'UpdateDriver';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateDriverInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  alternativePhone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  driverRates?: InputMaybe<Scalars['Int']>;
  drivingLicense?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  portPass?: InputMaybe<Scalars['String']>;
  portPassExpiryDate?: InputMaybe<Scalars['DateTime']>;
  portPassPermanentLori?: InputMaybe<Scalars['String']>;
  portPassTempLori?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  trakitAssetId?: InputMaybe<Scalars['Int']>;
  trakitEntId?: InputMaybe<Scalars['Int']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  turnboyName?: InputMaybe<Scalars['String']>;
  turnboyNumber?: InputMaybe<Scalars['String']>;
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateDriverStatus = {
  __typename?: 'UpdateDriverStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateFuelStation = {
  __typename?: 'UpdateFuelStation';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateFuelStationInput = {
  city?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fuelStationDiscount?: InputMaybe<Scalars['Int']>;
  geoId?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

export type UpdateFuelStationStatus = {
  __typename?: 'UpdateFuelStationStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateRegion = {
  __typename?: 'UpdateRegion';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateRegionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  createdBy?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sourceApplication?: InputMaybe<Scalars['String']>;
};

export type UpdateTrailer = {
  __typename?: 'UpdateTrailer';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateTrailerInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  cargoCapacity?: InputMaybe<Scalars['Float']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  linkedTo?: InputMaybe<Scalars['Int']>;
  loadingType?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  trailerOnBoarded?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  trailerTypeId?: InputMaybe<Scalars['Int']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
  yom?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTrailerStatus = {
  __typename?: 'UpdateTrailerStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateTrailerType = {
  __typename?: 'UpdateTrailerType';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateTransporter = {
  __typename?: 'UpdateTransporter';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateTransporterInput = {
  accountManagerEmail?: InputMaybe<Scalars['String']>;
  accountManger?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  bankAccountCurrency?: InputMaybe<Scalars['String']>;
  bankAccountName?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAddress?: InputMaybe<Scalars['String']>;
  bankCode?: InputMaybe<Scalars['String']>;
  bankName?: InputMaybe<Scalars['String']>;
  bankNameBranch?: InputMaybe<Scalars['String']>;
  bankSwiftCode?: InputMaybe<Scalars['String']>;
  beneficiaryAddress?: InputMaybe<Scalars['String']>;
  beneficiaryBusinessName?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactPerson?: InputMaybe<Scalars['String']>;
  contactPersonEmail?: InputMaybe<Scalars['String']>;
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  directorName?: InputMaybe<Scalars['String']>;
  gitInsurance?: InputMaybe<Scalars['String']>;
  gitInsuranceExpiryDate?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBlacklisted?: InputMaybe<Scalars['Boolean']>;
  kraIn?: InputMaybe<Scalars['String']>;
  kraTcc?: InputMaybe<Scalars['Upload']>;
  kwatosCode?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  podAssignment?: InputMaybe<Scalars['String']>;
  portSticker?: InputMaybe<Scalars['String']>;
  proofOfDirectorship?: InputMaybe<Scalars['Upload']>;
  qboCustomerIdLocal?: InputMaybe<Scalars['String']>;
  qboCustomerIdUsd?: InputMaybe<Scalars['String']>;
  qboVendorIdLocal?: InputMaybe<Scalars['String']>;
  qboVendorIdUsd?: InputMaybe<Scalars['String']>;
  registrationCertificate?: InputMaybe<Scalars['Upload']>;
  roadWorthinessCertificate?: InputMaybe<Scalars['Upload']>;
  trakitEntityId?: InputMaybe<Scalars['Int']>;
  transporterPaymentPeriod?: InputMaybe<Scalars['String']>;
  transporterPaymentTerms?: InputMaybe<Scalars['Float']>;
};

export type UpdateTransporterStatus = {
  __typename?: 'UpdateTransporterStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateTruck = {
  __typename?: 'UpdateTruck';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateTruckInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  cargoCapacity?: InputMaybe<Scalars['Float']>;
  country?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  ectsExpiry?: InputMaybe<Scalars['DateTime']>;
  ectsLicense?: InputMaybe<Scalars['String']>;
  ectsProvider?: InputMaybe<Scalars['String']>;
  gitInsurance?: InputMaybe<Scalars['String']>;
  insuranceExpiry?: InputMaybe<Scalars['DateTime']>;
  insuranceProvider?: InputMaybe<Scalars['String']>;
  journeyHours?: InputMaybe<Scalars['Float']>;
  kwatos?: InputMaybe<Scalars['String']>;
  linkedTo?: InputMaybe<Scalars['Int']>;
  loadingType?: InputMaybe<Scalars['String']>;
  loriCaptiveScheme?: InputMaybe<Scalars['String']>;
  loriInsuranceScheme?: InputMaybe<Scalars['String']>;
  make?: InputMaybe<Scalars['String']>;
  match?: InputMaybe<Scalars['DateTime']>;
  model?: InputMaybe<Scalars['String']>;
  moveId?: InputMaybe<Scalars['Int']>;
  ntsaInspectionExpiry?: InputMaybe<Scalars['DateTime']>;
  onBoardingStatus?: InputMaybe<Scalars['String']>;
  portPass?: InputMaybe<Scalars['String']>;
  portPassExpiry?: InputMaybe<Scalars['DateTime']>;
  region?: InputMaybe<Scalars['String']>;
  registeredAtIcd?: InputMaybe<Scalars['String']>;
  roadWorthinessCertificate?: InputMaybe<Scalars['String']>;
  tglTransitPassComesa?: InputMaybe<Scalars['String']>;
  tglTransitPassExpiry?: InputMaybe<Scalars['String']>;
  trailerReg?: InputMaybe<Scalars['String']>;
  transitPass?: InputMaybe<Scalars['String']>;
  transporter?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  truckReg?: InputMaybe<Scalars['String']>;
  truckTypeId?: InputMaybe<Scalars['Int']>;
  vettedBy?: InputMaybe<Scalars['String']>;
  vettedDate?: InputMaybe<Scalars['DateTime']>;
  yom?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateTruckStatus = {
  __typename?: 'UpdateTruckStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateTruckType = {
  __typename?: 'UpdateTruckType';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateVessel = {
  __typename?: 'UpdateVessel';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UpdateVesselInput = {
  country?: InputMaybe<Scalars['String']>;
  imo?: InputMaybe<Scalars['String']>;
  locDest?: InputMaybe<Scalars['String']>;
  mmsi?: InputMaybe<Scalars['Int']>;
  port?: InputMaybe<Scalars['String']>;
  received?: InputMaybe<Scalars['String']>;
  vesselName?: InputMaybe<Scalars['String']>;
  vesselSize?: InputMaybe<Scalars['String']>;
};

export type UpdateVesselStatus = {
  __typename?: 'UpdateVesselStatus';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type UploadDocument = {
  __typename?: 'UploadDocument';
  message?: Maybe<Scalars['String']>;
  rowCount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type Vessel = VesselNode & {
  __typename?: 'Vessel';
  country?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imo?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  locDest?: Maybe<Scalars['String']>;
  mmsi?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['String']>;
  vesselName: Scalars['String'];
  vesselSize?: Maybe<Scalars['String']>;
};

export type VesselConnection = {
  __typename?: 'VesselConnection';
  edges: Array<Maybe<VesselEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VesselEdge = {
  __typename?: 'VesselEdge';
  cursor: Scalars['String'];
  node?: Maybe<Vessel>;
  other?: Maybe<Scalars['String']>;
};

export type VesselNode = {
  id: Scalars['ID'];
};

export enum VesselSortEnum {
  CountryAsc = 'COUNTRY_ASC',
  CountryDesc = 'COUNTRY_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  ImoAsc = 'IMO_ASC',
  ImoDesc = 'IMO_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  LocDestAsc = 'LOC_DEST_ASC',
  LocDestDesc = 'LOC_DEST_DESC',
  MmsiAsc = 'MMSI_ASC',
  MmsiDesc = 'MMSI_DESC',
  PortAsc = 'PORT_ASC',
  PortDesc = 'PORT_DESC',
  ReceivedAsc = 'RECEIVED_ASC',
  ReceivedDesc = 'RECEIVED_DESC',
  VesselNameAsc = 'VESSEL_NAME_ASC',
  VesselNameDesc = 'VESSEL_NAME_DESC',
  VesselSizeAsc = 'VESSEL_SIZE_ASC',
  VesselSizeDesc = 'VESSEL_SIZE_DESC'
}

export type BulkImportDriversMutationVariables = Exact<{
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
  source: Source;
  transporterId: Scalars['Int'];
  country: Scalars['String'];
}>;


export type BulkImportDriversMutation = { __typename?: 'RootMutation', bulkImportDrivers?: { __typename?: 'BulkImportDrivers', message?: string | null | undefined, status?: number | null | undefined, validationErrors?: any | null | undefined } | null | undefined };

export type BulkImportTrailersMutationVariables = Exact<{
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
  source: Source;
  transporterId: Scalars['Int'];
  country: Scalars['String'];
}>;


export type BulkImportTrailersMutation = { __typename?: 'RootMutation', bulkImportTrailers?: { __typename?: 'BulkImportTrailers', message?: string | null | undefined, status?: number | null | undefined, validationErrors?: any | null | undefined } | null | undefined };

export type BulkImportTrucksMutationVariables = Exact<{
  file: Scalars['Upload'];
  mappedColumns: Scalars['GenericScalar'];
  source: Source;
  transporterId: Scalars['Int'];
  country: Scalars['String'];
}>;


export type BulkImportTrucksMutation = { __typename?: 'RootMutation', bulkImportTrucks?: { __typename?: 'BulkImportTrucks', message?: string | null | undefined, status?: number | null | undefined, validationErrors?: any | null | undefined } | null | undefined };

export type BulkUploadDriversMutationVariables = Exact<{
  file: Scalars['Upload'];
  transporterId: Scalars['Int'];
  country: Scalars['String'];
  source: Source;
}>;


export type BulkUploadDriversMutation = { __typename?: 'RootMutation', bulkUploadDrivers?: { __typename?: 'BulkUploadDrivers', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type BulkUploadTrailersMutationVariables = Exact<{
  file: Scalars['Upload'];
  transporterId: Scalars['Int'];
  country: Scalars['String'];
  source: Source;
}>;


export type BulkUploadTrailersMutation = { __typename?: 'RootMutation', bulkUploadTrailers?: { __typename?: 'BulkUploadTrailers', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type BulkUploadTrucksMutationVariables = Exact<{
  file: Scalars['Upload'];
  transporterId: Scalars['Int'];
  country: Scalars['String'];
  source: Source;
}>;


export type BulkUploadTrucksMutation = { __typename?: 'RootMutation', bulkUploadTrucks?: { __typename?: 'BulkUploadTrucks', message?: string | null | undefined, status?: number | null | undefined } | null | undefined };

export type DeleteDriverMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDriverMutation = { __typename?: 'RootMutation', deleteDriver?: { __typename?: 'DeleteDriver', message?: string | null | undefined, status?: number | null | undefined } | null | undefined };

export type DeleteTrailerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTrailerMutation = { __typename?: 'RootMutation', deleteTrailer?: { __typename?: 'DeleteTrailer', message?: string | null | undefined, status?: number | null | undefined } | null | undefined };

export type DeleteTruckMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTruckMutation = { __typename?: 'RootMutation', deleteTruck?: { __typename?: 'DeleteTruck', message?: string | null | undefined, status?: number | null | undefined } | null | undefined };

export type CreateDriverMutationVariables = Exact<{
  idNumber: Scalars['String'];
  phone: Scalars['String'];
  country: Scalars['String'];
  names: Scalars['String'];
  transporterId: Scalars['Int'];
  source: Source;
  createdBy?: Maybe<Scalars['String']>;
}>;


export type CreateDriverMutation = { __typename?: 'RootMutation', createDriver?: { __typename?: 'CreateDriver', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type CreateTrailerMutationVariables = Exact<{
  cargoCapacity: Scalars['Float'];
  trailerTypeId: Scalars['Int'];
  country: Scalars['String'];
  transporterId: Scalars['Int'];
  trailerReg: Scalars['String'];
  source: Source;
  createdBy?: Maybe<Scalars['String']>;
}>;


export type CreateTrailerMutation = { __typename?: 'RootMutation', createTrailer?: { __typename?: 'CreateTrailer', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type CreateTruckMutationVariables = Exact<{
  truckReg: Scalars['String'];
  transporterId: Scalars['Int'];
  country: Scalars['String'];
  truckTypeId: Scalars['Int'];
  source: Source;
  createdBy?: Maybe<Scalars['String']>;
}>;


export type CreateTruckMutation = { __typename?: 'RootMutation', createTruck?: { __typename?: 'CreateTruck', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type UpdateDriverMutationVariables = Exact<{
  id: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type UpdateDriverMutation = { __typename?: 'RootMutation', updateDriver?: { __typename?: 'UpdateDriver', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type UpdateTrailerMutationVariables = Exact<{
  id: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type UpdateTrailerMutation = { __typename?: 'RootMutation', updateTrailer?: { __typename?: 'UpdateTrailer', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type UpdateTruckMutationVariables = Exact<{
  id: Scalars['Int'];
  active: Scalars['Boolean'];
}>;


export type UpdateTruckMutation = { __typename?: 'RootMutation', updateTruck?: { __typename?: 'UpdateTruck', status?: number | null | undefined, message?: string | null | undefined } | null | undefined };

export type GetAssetIdsQueryVariables = Exact<{
  assetType: AssetType;
  transporterId: Scalars['Int'];
}>;


export type GetAssetIdsQuery = { __typename?: 'RootQuery', assetIds?: Array<number | null | undefined> | null | undefined };

export type GetSupplyDriversQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  status?: Maybe<LinkEnum>;
  region?: Maybe<RegionEnum>;
  active?: Maybe<ActiveEnum>;
}>;


export type GetSupplyDriversQuery = { __typename?: 'RootQuery', drivers?: { __typename?: 'DriverConnectionsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null | undefined }, edges: Array<{ __typename?: 'DriverConnectionsEdge', node?: { __typename?: 'Driver', names?: string | null | undefined, idNumber?: string | null | undefined, country?: string | null | undefined, active?: boolean | null | undefined, phone?: string | null | undefined, transporterId?: number | null | undefined, id: string } | null | undefined } | null | undefined> } | null | undefined };

export type GetTrailerTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrailerTypesQuery = { __typename?: 'RootQuery', trailerTypes?: { __typename?: 'TrailerTypesConnection', edges: Array<{ __typename?: 'TrailerTypesEdge', node?: { __typename?: 'TrailerTypes', name?: string | null | undefined, id: string, trailerType?: { __typename?: 'Trailer', cargoCapacity?: number | null | undefined } | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type GetSupplyTrailersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  region?: Maybe<RegionEnum>;
  active?: Maybe<ActiveEnum>;
  status?: Maybe<LinkEnum>;
}>;


export type GetSupplyTrailersQuery = { __typename?: 'RootQuery', trailers?: { __typename?: 'TrailerConnectionsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null | undefined }, edges: Array<{ __typename?: 'TrailerConnectionsEdge', node?: { __typename?: 'Trailer', cargoCapacity?: number | null | undefined, trailerReg?: string | null | undefined, transporterId?: number | null | undefined, trailerType?: string | null | undefined, id: string, active?: boolean | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type GetSupplyTruckTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSupplyTruckTypesQuery = { __typename?: 'RootQuery', truckTypes?: { __typename?: 'TruckTypeConnection', edges: Array<{ __typename?: 'TruckTypeEdge', node?: { __typename?: 'TruckTypes', id: string, name?: string | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export type GetSupplyTrucksQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  active?: Maybe<ActiveEnum>;
  status?: Maybe<MatchEnum>;
  region?: Maybe<RegionEnum>;
}>;


export type GetSupplyTrucksQuery = { __typename?: 'RootQuery', trucks?: { __typename?: 'TruckConnectionsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null | undefined }, edges: Array<{ __typename?: 'TruckConnectionsEdge', node?: { __typename?: 'Truck', truckReg?: string | null | undefined, transporterId?: number | null | undefined, active?: boolean | null | undefined, id: string, match?: any | null | undefined } | null | undefined } | null | undefined> } | null | undefined };


export const BulkImportDriversDocument = gql`
    mutation bulkImportDrivers($file: Upload!, $mappedColumns: GenericScalar!, $source: Source!, $transporterId: Int!, $country: String!) {
  bulkImportDrivers(file: $file, mappedColumns: $mappedColumns, source: $source, transporterId: $transporterId, country: $country) {
    message
    status
    validationErrors
  }
}
    `;
export type BulkImportDriversMutationFn = Apollo.MutationFunction<BulkImportDriversMutation, BulkImportDriversMutationVariables>;

/**
 * __useBulkImportDriversMutation__
 *
 * To run a mutation, you first call `useBulkImportDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkImportDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkImportDriversMutation, { data, loading, error }] = useBulkImportDriversMutation({
 *   variables: {
 *      file: // value for 'file'
 *      mappedColumns: // value for 'mappedColumns'
 *      source: // value for 'source'
 *      transporterId: // value for 'transporterId'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useBulkImportDriversMutation(baseOptions?: Apollo.MutationHookOptions<BulkImportDriversMutation, BulkImportDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkImportDriversMutation, BulkImportDriversMutationVariables>(BulkImportDriversDocument, options);
      }
export type BulkImportDriversMutationHookResult = ReturnType<typeof useBulkImportDriversMutation>;
export type BulkImportDriversMutationResult = Apollo.MutationResult<BulkImportDriversMutation>;
export type BulkImportDriversMutationOptions = Apollo.BaseMutationOptions<BulkImportDriversMutation, BulkImportDriversMutationVariables>;
export const BulkImportTrailersDocument = gql`
    mutation bulkImportTrailers($file: Upload!, $mappedColumns: GenericScalar!, $source: Source!, $transporterId: Int!, $country: String!) {
  bulkImportTrailers(file: $file, mappedColumns: $mappedColumns, source: $source, transporterId: $transporterId, country: $country) {
    message
    status
    validationErrors
  }
}
    `;
export type BulkImportTrailersMutationFn = Apollo.MutationFunction<BulkImportTrailersMutation, BulkImportTrailersMutationVariables>;

/**
 * __useBulkImportTrailersMutation__
 *
 * To run a mutation, you first call `useBulkImportTrailersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkImportTrailersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkImportTrailersMutation, { data, loading, error }] = useBulkImportTrailersMutation({
 *   variables: {
 *      file: // value for 'file'
 *      mappedColumns: // value for 'mappedColumns'
 *      source: // value for 'source'
 *      transporterId: // value for 'transporterId'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useBulkImportTrailersMutation(baseOptions?: Apollo.MutationHookOptions<BulkImportTrailersMutation, BulkImportTrailersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkImportTrailersMutation, BulkImportTrailersMutationVariables>(BulkImportTrailersDocument, options);
      }
export type BulkImportTrailersMutationHookResult = ReturnType<typeof useBulkImportTrailersMutation>;
export type BulkImportTrailersMutationResult = Apollo.MutationResult<BulkImportTrailersMutation>;
export type BulkImportTrailersMutationOptions = Apollo.BaseMutationOptions<BulkImportTrailersMutation, BulkImportTrailersMutationVariables>;
export const BulkImportTrucksDocument = gql`
    mutation bulkImportTrucks($file: Upload!, $mappedColumns: GenericScalar!, $source: Source!, $transporterId: Int!, $country: String!) {
  bulkImportTrucks(file: $file, mappedColumns: $mappedColumns, source: $source, transporterId: $transporterId, country: $country) {
    message
    status
    validationErrors
  }
}
    `;
export type BulkImportTrucksMutationFn = Apollo.MutationFunction<BulkImportTrucksMutation, BulkImportTrucksMutationVariables>;

/**
 * __useBulkImportTrucksMutation__
 *
 * To run a mutation, you first call `useBulkImportTrucksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkImportTrucksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkImportTrucksMutation, { data, loading, error }] = useBulkImportTrucksMutation({
 *   variables: {
 *      file: // value for 'file'
 *      mappedColumns: // value for 'mappedColumns'
 *      source: // value for 'source'
 *      transporterId: // value for 'transporterId'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useBulkImportTrucksMutation(baseOptions?: Apollo.MutationHookOptions<BulkImportTrucksMutation, BulkImportTrucksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkImportTrucksMutation, BulkImportTrucksMutationVariables>(BulkImportTrucksDocument, options);
      }
export type BulkImportTrucksMutationHookResult = ReturnType<typeof useBulkImportTrucksMutation>;
export type BulkImportTrucksMutationResult = Apollo.MutationResult<BulkImportTrucksMutation>;
export type BulkImportTrucksMutationOptions = Apollo.BaseMutationOptions<BulkImportTrucksMutation, BulkImportTrucksMutationVariables>;
export const BulkUploadDriversDocument = gql`
    mutation bulkUploadDrivers($file: Upload!, $transporterId: Int!, $country: String!, $source: Source!) {
  bulkUploadDrivers(file: $file, transporterId: $transporterId, country: $country, source: $source) {
    status
    message
  }
}
    `;
export type BulkUploadDriversMutationFn = Apollo.MutationFunction<BulkUploadDriversMutation, BulkUploadDriversMutationVariables>;

/**
 * __useBulkUploadDriversMutation__
 *
 * To run a mutation, you first call `useBulkUploadDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUploadDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUploadDriversMutation, { data, loading, error }] = useBulkUploadDriversMutation({
 *   variables: {
 *      file: // value for 'file'
 *      transporterId: // value for 'transporterId'
 *      country: // value for 'country'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useBulkUploadDriversMutation(baseOptions?: Apollo.MutationHookOptions<BulkUploadDriversMutation, BulkUploadDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUploadDriversMutation, BulkUploadDriversMutationVariables>(BulkUploadDriversDocument, options);
      }
export type BulkUploadDriversMutationHookResult = ReturnType<typeof useBulkUploadDriversMutation>;
export type BulkUploadDriversMutationResult = Apollo.MutationResult<BulkUploadDriversMutation>;
export type BulkUploadDriversMutationOptions = Apollo.BaseMutationOptions<BulkUploadDriversMutation, BulkUploadDriversMutationVariables>;
export const BulkUploadTrailersDocument = gql`
    mutation bulkUploadTrailers($file: Upload!, $transporterId: Int!, $country: String!, $source: Source!) {
  bulkUploadTrailers(file: $file, transporterId: $transporterId, country: $country, source: $source) {
    status
    message
  }
}
    `;
export type BulkUploadTrailersMutationFn = Apollo.MutationFunction<BulkUploadTrailersMutation, BulkUploadTrailersMutationVariables>;

/**
 * __useBulkUploadTrailersMutation__
 *
 * To run a mutation, you first call `useBulkUploadTrailersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUploadTrailersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUploadTrailersMutation, { data, loading, error }] = useBulkUploadTrailersMutation({
 *   variables: {
 *      file: // value for 'file'
 *      transporterId: // value for 'transporterId'
 *      country: // value for 'country'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useBulkUploadTrailersMutation(baseOptions?: Apollo.MutationHookOptions<BulkUploadTrailersMutation, BulkUploadTrailersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUploadTrailersMutation, BulkUploadTrailersMutationVariables>(BulkUploadTrailersDocument, options);
      }
export type BulkUploadTrailersMutationHookResult = ReturnType<typeof useBulkUploadTrailersMutation>;
export type BulkUploadTrailersMutationResult = Apollo.MutationResult<BulkUploadTrailersMutation>;
export type BulkUploadTrailersMutationOptions = Apollo.BaseMutationOptions<BulkUploadTrailersMutation, BulkUploadTrailersMutationVariables>;
export const BulkUploadTrucksDocument = gql`
    mutation bulkUploadTrucks($file: Upload!, $transporterId: Int!, $country: String!, $source: Source!) {
  bulkUploadTrucks(file: $file, transporterId: $transporterId, country: $country, source: $source) {
    message
    status
  }
}
    `;
export type BulkUploadTrucksMutationFn = Apollo.MutationFunction<BulkUploadTrucksMutation, BulkUploadTrucksMutationVariables>;

/**
 * __useBulkUploadTrucksMutation__
 *
 * To run a mutation, you first call `useBulkUploadTrucksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUploadTrucksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUploadTrucksMutation, { data, loading, error }] = useBulkUploadTrucksMutation({
 *   variables: {
 *      file: // value for 'file'
 *      transporterId: // value for 'transporterId'
 *      country: // value for 'country'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useBulkUploadTrucksMutation(baseOptions?: Apollo.MutationHookOptions<BulkUploadTrucksMutation, BulkUploadTrucksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUploadTrucksMutation, BulkUploadTrucksMutationVariables>(BulkUploadTrucksDocument, options);
      }
export type BulkUploadTrucksMutationHookResult = ReturnType<typeof useBulkUploadTrucksMutation>;
export type BulkUploadTrucksMutationResult = Apollo.MutationResult<BulkUploadTrucksMutation>;
export type BulkUploadTrucksMutationOptions = Apollo.BaseMutationOptions<BulkUploadTrucksMutation, BulkUploadTrucksMutationVariables>;
export const DeleteDriverDocument = gql`
    mutation deleteDriver($id: Int!) {
  deleteDriver(id: $id) {
    message
    status
  }
}
    `;
export type DeleteDriverMutationFn = Apollo.MutationFunction<DeleteDriverMutation, DeleteDriverMutationVariables>;

/**
 * __useDeleteDriverMutation__
 *
 * To run a mutation, you first call `useDeleteDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverMutation, { data, loading, error }] = useDeleteDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDriverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDriverMutation, DeleteDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDriverMutation, DeleteDriverMutationVariables>(DeleteDriverDocument, options);
      }
export type DeleteDriverMutationHookResult = ReturnType<typeof useDeleteDriverMutation>;
export type DeleteDriverMutationResult = Apollo.MutationResult<DeleteDriverMutation>;
export type DeleteDriverMutationOptions = Apollo.BaseMutationOptions<DeleteDriverMutation, DeleteDriverMutationVariables>;
export const DeleteTrailerDocument = gql`
    mutation deleteTrailer($id: Int!) {
  deleteTrailer(id: $id) {
    message
    status
  }
}
    `;
export type DeleteTrailerMutationFn = Apollo.MutationFunction<DeleteTrailerMutation, DeleteTrailerMutationVariables>;

/**
 * __useDeleteTrailerMutation__
 *
 * To run a mutation, you first call `useDeleteTrailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrailerMutation, { data, loading, error }] = useDeleteTrailerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrailerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrailerMutation, DeleteTrailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrailerMutation, DeleteTrailerMutationVariables>(DeleteTrailerDocument, options);
      }
export type DeleteTrailerMutationHookResult = ReturnType<typeof useDeleteTrailerMutation>;
export type DeleteTrailerMutationResult = Apollo.MutationResult<DeleteTrailerMutation>;
export type DeleteTrailerMutationOptions = Apollo.BaseMutationOptions<DeleteTrailerMutation, DeleteTrailerMutationVariables>;
export const DeleteTruckDocument = gql`
    mutation deleteTruck($id: Int!) {
  deleteTruck(id: $id) {
    message
    status
  }
}
    `;
export type DeleteTruckMutationFn = Apollo.MutationFunction<DeleteTruckMutation, DeleteTruckMutationVariables>;

/**
 * __useDeleteTruckMutation__
 *
 * To run a mutation, you first call `useDeleteTruckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTruckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTruckMutation, { data, loading, error }] = useDeleteTruckMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTruckMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTruckMutation, DeleteTruckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTruckMutation, DeleteTruckMutationVariables>(DeleteTruckDocument, options);
      }
export type DeleteTruckMutationHookResult = ReturnType<typeof useDeleteTruckMutation>;
export type DeleteTruckMutationResult = Apollo.MutationResult<DeleteTruckMutation>;
export type DeleteTruckMutationOptions = Apollo.BaseMutationOptions<DeleteTruckMutation, DeleteTruckMutationVariables>;
export const CreateDriverDocument = gql`
    mutation createDriver($idNumber: String!, $phone: String!, $country: String!, $names: String!, $transporterId: Int!, $source: Source!, $createdBy: String) {
  createDriver(source: $source, driverData: {idNumber: $idNumber, phone: $phone, country: $country, names: $names, transporterId: $transporterId, createdBy: $createdBy}) {
    status
    message
  }
}
    `;
export type CreateDriverMutationFn = Apollo.MutationFunction<CreateDriverMutation, CreateDriverMutationVariables>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      idNumber: // value for 'idNumber'
 *      phone: // value for 'phone'
 *      country: // value for 'country'
 *      names: // value for 'names'
 *      transporterId: // value for 'transporterId'
 *      source: // value for 'source'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useCreateDriverMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriverMutation, CreateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriverMutation, CreateDriverMutationVariables>(CreateDriverDocument, options);
      }
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = Apollo.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<CreateDriverMutation, CreateDriverMutationVariables>;
export const CreateTrailerDocument = gql`
    mutation createTrailer($cargoCapacity: Float!, $trailerTypeId: Int!, $country: String!, $transporterId: Int!, $trailerReg: String!, $source: Source!, $createdBy: String) {
  createTrailer(source: $source, trailerData: {cargoCapacity: $cargoCapacity, trailerTypeId: $trailerTypeId, country: $country, transporterId: $transporterId, trailerReg: $trailerReg, createdBy: $createdBy}) {
    status
    message
  }
}
    `;
export type CreateTrailerMutationFn = Apollo.MutationFunction<CreateTrailerMutation, CreateTrailerMutationVariables>;

/**
 * __useCreateTrailerMutation__
 *
 * To run a mutation, you first call `useCreateTrailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrailerMutation, { data, loading, error }] = useCreateTrailerMutation({
 *   variables: {
 *      cargoCapacity: // value for 'cargoCapacity'
 *      trailerTypeId: // value for 'trailerTypeId'
 *      country: // value for 'country'
 *      transporterId: // value for 'transporterId'
 *      trailerReg: // value for 'trailerReg'
 *      source: // value for 'source'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useCreateTrailerMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrailerMutation, CreateTrailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrailerMutation, CreateTrailerMutationVariables>(CreateTrailerDocument, options);
      }
export type CreateTrailerMutationHookResult = ReturnType<typeof useCreateTrailerMutation>;
export type CreateTrailerMutationResult = Apollo.MutationResult<CreateTrailerMutation>;
export type CreateTrailerMutationOptions = Apollo.BaseMutationOptions<CreateTrailerMutation, CreateTrailerMutationVariables>;
export const CreateTruckDocument = gql`
    mutation createTruck($truckReg: String!, $transporterId: Int!, $country: String!, $truckTypeId: Int!, $source: Source!, $createdBy: String) {
  createTruck(source: $source, truckData: {truckReg: $truckReg, transporterId: $transporterId, country: $country, truckTypeId: $truckTypeId, createdBy: $createdBy}) {
    status
    message
  }
}
    `;
export type CreateTruckMutationFn = Apollo.MutationFunction<CreateTruckMutation, CreateTruckMutationVariables>;

/**
 * __useCreateTruckMutation__
 *
 * To run a mutation, you first call `useCreateTruckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTruckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTruckMutation, { data, loading, error }] = useCreateTruckMutation({
 *   variables: {
 *      truckReg: // value for 'truckReg'
 *      transporterId: // value for 'transporterId'
 *      country: // value for 'country'
 *      truckTypeId: // value for 'truckTypeId'
 *      source: // value for 'source'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useCreateTruckMutation(baseOptions?: Apollo.MutationHookOptions<CreateTruckMutation, CreateTruckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTruckMutation, CreateTruckMutationVariables>(CreateTruckDocument, options);
      }
export type CreateTruckMutationHookResult = ReturnType<typeof useCreateTruckMutation>;
export type CreateTruckMutationResult = Apollo.MutationResult<CreateTruckMutation>;
export type CreateTruckMutationOptions = Apollo.BaseMutationOptions<CreateTruckMutation, CreateTruckMutationVariables>;
export const UpdateDriverDocument = gql`
    mutation updateDriver($id: Int!, $active: Boolean!) {
  updateDriver(id: $id, driverData: {active: $active}) {
    status
    message
  }
}
    `;
export type UpdateDriverMutationFn = Apollo.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, options);
      }
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<UpdateDriverMutation, UpdateDriverMutationVariables>;
export const UpdateTrailerDocument = gql`
    mutation updateTrailer($id: Int!, $active: Boolean!) {
  updateTrailer(id: $id, trailerData: {active: $active}) {
    status
    message
  }
}
    `;
export type UpdateTrailerMutationFn = Apollo.MutationFunction<UpdateTrailerMutation, UpdateTrailerMutationVariables>;

/**
 * __useUpdateTrailerMutation__
 *
 * To run a mutation, you first call `useUpdateTrailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrailerMutation, { data, loading, error }] = useUpdateTrailerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateTrailerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrailerMutation, UpdateTrailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrailerMutation, UpdateTrailerMutationVariables>(UpdateTrailerDocument, options);
      }
export type UpdateTrailerMutationHookResult = ReturnType<typeof useUpdateTrailerMutation>;
export type UpdateTrailerMutationResult = Apollo.MutationResult<UpdateTrailerMutation>;
export type UpdateTrailerMutationOptions = Apollo.BaseMutationOptions<UpdateTrailerMutation, UpdateTrailerMutationVariables>;
export const UpdateTruckDocument = gql`
    mutation updateTruck($id: Int!, $active: Boolean!) {
  updateTruck(id: $id, truckData: {active: $active}) {
    status
    message
  }
}
    `;
export type UpdateTruckMutationFn = Apollo.MutationFunction<UpdateTruckMutation, UpdateTruckMutationVariables>;

/**
 * __useUpdateTruckMutation__
 *
 * To run a mutation, you first call `useUpdateTruckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTruckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTruckMutation, { data, loading, error }] = useUpdateTruckMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateTruckMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTruckMutation, UpdateTruckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTruckMutation, UpdateTruckMutationVariables>(UpdateTruckDocument, options);
      }
export type UpdateTruckMutationHookResult = ReturnType<typeof useUpdateTruckMutation>;
export type UpdateTruckMutationResult = Apollo.MutationResult<UpdateTruckMutation>;
export type UpdateTruckMutationOptions = Apollo.BaseMutationOptions<UpdateTruckMutation, UpdateTruckMutationVariables>;
export const GetAssetIdsDocument = gql`
    query getAssetIds($assetType: AssetType!, $transporterId: Int!) {
  assetIds(assetType: $assetType, transporterId: $transporterId)
}
    `;

/**
 * __useGetAssetIdsQuery__
 *
 * To run a query within a React component, call `useGetAssetIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetIdsQuery({
 *   variables: {
 *      assetType: // value for 'assetType'
 *      transporterId: // value for 'transporterId'
 *   },
 * });
 */
export function useGetAssetIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAssetIdsQuery, GetAssetIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetIdsQuery, GetAssetIdsQueryVariables>(GetAssetIdsDocument, options);
      }
export function useGetAssetIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetIdsQuery, GetAssetIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetIdsQuery, GetAssetIdsQueryVariables>(GetAssetIdsDocument, options);
        }
export type GetAssetIdsQueryHookResult = ReturnType<typeof useGetAssetIdsQuery>;
export type GetAssetIdsLazyQueryHookResult = ReturnType<typeof useGetAssetIdsLazyQuery>;
export type GetAssetIdsQueryResult = Apollo.QueryResult<GetAssetIdsQuery, GetAssetIdsQueryVariables>;
export const GetSupplyDriversDocument = gql`
    query getSupplyDrivers($first: Int, $after: String, $before: String, $search: String, $transporterId: Int, $status: LinkEnum, $region: RegionEnum, $active: ActiveEnum) {
  drivers(first: $first, after: $after, before: $before, search: $search, transporterId: $transporterId, status: $status, region: $region, active: $active, sort: CREATED_AT_DESC) {
    pageInfo {
      hasNextPage
      endCursor
      hasNextPage
    }
    edges {
      node {
        names
        idNumber
        names
        country
        active
        phone
        transporterId
        id
        active
      }
    }
  }
}
    `;

/**
 * __useGetSupplyDriversQuery__
 *
 * To run a query within a React component, call `useGetSupplyDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplyDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplyDriversQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      transporterId: // value for 'transporterId'
 *      status: // value for 'status'
 *      region: // value for 'region'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetSupplyDriversQuery(baseOptions?: Apollo.QueryHookOptions<GetSupplyDriversQuery, GetSupplyDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplyDriversQuery, GetSupplyDriversQueryVariables>(GetSupplyDriversDocument, options);
      }
export function useGetSupplyDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplyDriversQuery, GetSupplyDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplyDriversQuery, GetSupplyDriversQueryVariables>(GetSupplyDriversDocument, options);
        }
export type GetSupplyDriversQueryHookResult = ReturnType<typeof useGetSupplyDriversQuery>;
export type GetSupplyDriversLazyQueryHookResult = ReturnType<typeof useGetSupplyDriversLazyQuery>;
export type GetSupplyDriversQueryResult = Apollo.QueryResult<GetSupplyDriversQuery, GetSupplyDriversQueryVariables>;
export const GetTrailerTypesDocument = gql`
    query getTrailerTypes {
  trailerTypes {
    edges {
      node {
        name
        id
        trailerType {
          cargoCapacity
        }
      }
    }
  }
}
    `;

/**
 * __useGetTrailerTypesQuery__
 *
 * To run a query within a React component, call `useGetTrailerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrailerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrailerTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrailerTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetTrailerTypesQuery, GetTrailerTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrailerTypesQuery, GetTrailerTypesQueryVariables>(GetTrailerTypesDocument, options);
      }
export function useGetTrailerTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrailerTypesQuery, GetTrailerTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrailerTypesQuery, GetTrailerTypesQueryVariables>(GetTrailerTypesDocument, options);
        }
export type GetTrailerTypesQueryHookResult = ReturnType<typeof useGetTrailerTypesQuery>;
export type GetTrailerTypesLazyQueryHookResult = ReturnType<typeof useGetTrailerTypesLazyQuery>;
export type GetTrailerTypesQueryResult = Apollo.QueryResult<GetTrailerTypesQuery, GetTrailerTypesQueryVariables>;
export const GetSupplyTrailersDocument = gql`
    query getSupplyTrailers($first: Int, $after: String, $before: String, $search: String, $transporterId: Int, $region: RegionEnum, $active: ActiveEnum, $status: LinkEnum) {
  trailers(first: $first, after: $after, before: $before, search: $search, transporterId: $transporterId, region: $region, active: $active, status: $status, sort: CREATED_AT_DESC) {
    pageInfo {
      hasNextPage
      endCursor
      hasNextPage
    }
    edges {
      node {
        cargoCapacity
        trailerReg
        transporterId
        trailerType
        id
        active
      }
    }
  }
}
    `;

/**
 * __useGetSupplyTrailersQuery__
 *
 * To run a query within a React component, call `useGetSupplyTrailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplyTrailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplyTrailersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      transporterId: // value for 'transporterId'
 *      region: // value for 'region'
 *      active: // value for 'active'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetSupplyTrailersQuery(baseOptions?: Apollo.QueryHookOptions<GetSupplyTrailersQuery, GetSupplyTrailersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplyTrailersQuery, GetSupplyTrailersQueryVariables>(GetSupplyTrailersDocument, options);
      }
export function useGetSupplyTrailersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplyTrailersQuery, GetSupplyTrailersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplyTrailersQuery, GetSupplyTrailersQueryVariables>(GetSupplyTrailersDocument, options);
        }
export type GetSupplyTrailersQueryHookResult = ReturnType<typeof useGetSupplyTrailersQuery>;
export type GetSupplyTrailersLazyQueryHookResult = ReturnType<typeof useGetSupplyTrailersLazyQuery>;
export type GetSupplyTrailersQueryResult = Apollo.QueryResult<GetSupplyTrailersQuery, GetSupplyTrailersQueryVariables>;
export const GetSupplyTruckTypesDocument = gql`
    query getSupplyTruckTypes {
  truckTypes {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetSupplyTruckTypesQuery__
 *
 * To run a query within a React component, call `useGetSupplyTruckTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplyTruckTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplyTruckTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupplyTruckTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetSupplyTruckTypesQuery, GetSupplyTruckTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplyTruckTypesQuery, GetSupplyTruckTypesQueryVariables>(GetSupplyTruckTypesDocument, options);
      }
export function useGetSupplyTruckTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplyTruckTypesQuery, GetSupplyTruckTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplyTruckTypesQuery, GetSupplyTruckTypesQueryVariables>(GetSupplyTruckTypesDocument, options);
        }
export type GetSupplyTruckTypesQueryHookResult = ReturnType<typeof useGetSupplyTruckTypesQuery>;
export type GetSupplyTruckTypesLazyQueryHookResult = ReturnType<typeof useGetSupplyTruckTypesLazyQuery>;
export type GetSupplyTruckTypesQueryResult = Apollo.QueryResult<GetSupplyTruckTypesQuery, GetSupplyTruckTypesQueryVariables>;
export const GetSupplyTrucksDocument = gql`
    query getSupplyTrucks($first: Int, $after: String, $before: String, $search: String, $transporterId: Int, $active: ActiveEnum, $status: MatchEnum, $region: RegionEnum) {
  trucks(first: $first, after: $after, before: $before, search: $search, transporterId: $transporterId, active: $active, status: $status, region: $region, sort: CREATED_AT_DESC) {
    pageInfo {
      hasNextPage
      endCursor
      hasNextPage
    }
    edges {
      node {
        truckReg
        transporterId
        active
        id
        match
      }
    }
  }
}
    `;

/**
 * __useGetSupplyTrucksQuery__
 *
 * To run a query within a React component, call `useGetSupplyTrucksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplyTrucksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplyTrucksQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      transporterId: // value for 'transporterId'
 *      active: // value for 'active'
 *      status: // value for 'status'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetSupplyTrucksQuery(baseOptions?: Apollo.QueryHookOptions<GetSupplyTrucksQuery, GetSupplyTrucksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupplyTrucksQuery, GetSupplyTrucksQueryVariables>(GetSupplyTrucksDocument, options);
      }
export function useGetSupplyTrucksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupplyTrucksQuery, GetSupplyTrucksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupplyTrucksQuery, GetSupplyTrucksQueryVariables>(GetSupplyTrucksDocument, options);
        }
export type GetSupplyTrucksQueryHookResult = ReturnType<typeof useGetSupplyTrucksQuery>;
export type GetSupplyTrucksLazyQueryHookResult = ReturnType<typeof useGetSupplyTrucksLazyQuery>;
export type GetSupplyTrucksQueryResult = Apollo.QueryResult<GetSupplyTrucksQuery, GetSupplyTrucksQueryVariables>;