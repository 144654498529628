import { makeStyles } from '@material-ui/core/styles';
import { COLORS, STYLES } from 'utils';

export const useStyles = makeStyles({
	card: {
		...STYLES.card,
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: '1px solid #F9F9F9',
		boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.03)',
        borderRadius: 5,
		padding: 20,
	},
	item: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	title: {
		fontSize: 16,
		fontWeight: 400,
		marginBottom: 4,
	},
	value: {
		color: COLORS.primary,
		fontSize: 18,
		fontWeight: 800,
	},
	status: {
		color: COLORS.primary,
		width: '80px',
	},
	activeStatus: {
		color: '#228b22',
		flexDirection: 'column',
		fontSize: 12,
		width: 80,
	},
	inactiveStatus: {
		color: '#e17b0e',
		flexDirection: 'column',
		fontSize: 12,
		width: 80,
	},
	statusText: {
		fontSize: 11,
		fontWeight: 600,
		display: 'flex',
    	alignItems: 'center',
	},
	statusIndicator: {
		display: 'block',
		width: '12px',
		height: '12px',
		backgroundColor: '#E31010',
		borderRadius: '12px',
	},
	inactive: {
		backgroundColor: '#E31010',
	},
	active: {
		backgroundColor: '#28B772',
	},
	iconButton: {
		color: COLORS.primary,
		padding: 0,
		margin: '0 5px',
	},
	deleteIconButton: {
		color: '#E31010',
		padding: 0,
	},
	uploadIconButton: {
		color: COLORS.primary,
		marginLeft: 8,
		marginRight: -4,
	},
	extras: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	btn: {
		height: '40px',
		textTransform: 'capitalize',
		fontSize: 14,
		fontWeight: 500,
	},
	confirmDelete: {
		marginTop: 14,
		borderColor: COLORS.primary,
		backgroundColor: COLORS.primary,
	},
	modalDismissButton: {
		borderColor:  COLORS.primary,
	},
	deleteAssetDialog: {
		backgroundColor:  'rgba(0, 0, 0, 0.4)',
		backdropFilter: 'blur(1px)',
	},
	deleteAsset: {
		color: COLORS.red,
		fontSize: 16,
		lineHeight: '18px',
		fontWeight: 600,
		textAlign: "center",
	},
	itemInfoLabel: {
		color: COLORS.grey3,
		fontSize: 11,
		fontWeight: 600
	},
	itemInfoText: {
		color: COLORS.grey3,
		fontSize: 11,
		fontWeight: 600,
		marginLeft: 4
	},
});
