/* eslint-disable */
// WARNING: This file is automatically generated. Do not edit.
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Account = AccountNode & {
  __typename?: 'Account';
  accountName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  beneficiaryAddress?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  swiftCode?: Maybe<Scalars['String']>;
};

export type AccountNode = {
  id: Scalars['ID'];
};

export type AccountStatus = {
  __typename?: 'AccountStatus';
  isSuspended?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  suspensionReason?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ActivateExternalTransporter = {
  __typename?: 'ActivateExternalTransporter';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  transporter?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type ActivateGuest = {
  __typename?: 'ActivateGuest';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  transporter?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AssetPageInfo = {
  __typename?: 'AssetPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type Attributes = {
  __typename?: 'Attributes';
  accountSuspended?: Maybe<Scalars['Boolean']>;
  companyId?: Maybe<Scalars['Int']>;
  companyMobile?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  suspensionReason?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
};

export type AuthUserAttributes = {
  accepted_terms?: InputMaybe<Scalars['Boolean']>;
  first_login?: InputMaybe<Scalars['Boolean']>;
};

export type Company = CompanyNode & {
  __typename?: 'Company';
  account?: Maybe<Account>;
  companyEmail: Scalars['String'];
  companyMobile: Scalars['String'];
  companyName: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  directors?: Maybe<DirectorConnection>;
  documents?: Maybe<Document>;
  fleet?: Maybe<Fleet>;
  id: Scalars['ID'];
  isExternal?: Maybe<Scalars['Boolean']>;
  isOnboardedTransporter?: Maybe<Scalars['Boolean']>;
  onboardedtransporterId?: Maybe<Scalars['Int']>;
  onboardingNotified?: Maybe<Scalars['Boolean']>;
  region: Scalars['String'];
  transporterId?: Maybe<Scalars['Int']>;
};


export type CompanyDirectorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CompanyNode = {
  id: Scalars['ID'];
};

export type DemoteAdmin = {
  __typename?: 'DemoteAdmin';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type Director = DirectorNode & {
  __typename?: 'Director';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directorName?: Maybe<Scalars['String']>;
  directorNumber?: Maybe<Scalars['String']>;
  directorResidentialAddress?: Maybe<Scalars['String']>;
  directorTelNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DirectorConnection = {
  __typename?: 'DirectorConnection';
  edges: Array<Maybe<DirectorEdge>>;
  pageInfo: PageInfo;
};

export type DirectorEdge = {
  __typename?: 'DirectorEdge';
  cursor: Scalars['String'];
  node?: Maybe<Director>;
};

export type DirectorNode = {
  id: Scalars['ID'];
};

export type Document = DocumentNode & {
  __typename?: 'Document';
  bankLetter?: Maybe<Scalars['String']>;
  beeCertificate?: Maybe<Scalars['String']>;
  carrierResolution?: Maybe<Scalars['String']>;
  carriersLiability?: Maybe<Scalars['String']>;
  carriersLiabilityExpiry?: Maybe<Scalars['DateTime']>;
  certificateOfIncorporation?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  companyLetterhead?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  directorIdentification?: Maybe<Scalars['String']>;
  directorKraPin?: Maybe<Scalars['String']>;
  directorsIds?: Maybe<Scalars['String']>;
  gitCoverConfirmation?: Maybe<Scalars['String']>;
  gitCoverExpiry?: Maybe<Scalars['DateTime']>;
  goodsInTransitCertificate?: Maybe<Scalars['String']>;
  hasQualityManagementCert?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  insurancePolicies?: Maybe<Scalars['String']>;
  isVatRegistered?: Maybe<Scalars['Boolean']>;
  kraPin?: Maybe<Scalars['String']>;
  kraTcc?: Maybe<Scalars['String']>;
  letterOfGoodStanding?: Maybe<Scalars['String']>;
  proofOfDirectorship?: Maybe<Scalars['String']>;
  qualityManagementCertificate?: Maybe<Scalars['String']>;
  registrationCertificate?: Maybe<Scalars['String']>;
  signedLoriContract?: Maybe<Scalars['String']>;
  taxClearanceCertificate?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatCertificate?: Maybe<Scalars['String']>;
  vatRegistrationNumber?: Maybe<Scalars['String']>;
  vehicleRegistrationDocuments?: Maybe<Scalars['String']>;
};

export type DocumentNode = {
  id: Scalars['ID'];
};

export type Driver = {
  __typename?: 'Driver';
  active?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  driverId?: Maybe<Scalars['Int']>;
  driverIdNumber?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  driverPhoneNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
};

export type DriverEdge = {
  __typename?: 'DriverEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Driver>;
};

export type Drivers = {
  __typename?: 'Drivers';
  edges: Array<Maybe<DriverEdge>>;
  pageInfo?: Maybe<AssetPageInfo>;
};

export type Fleet = FleetNode & {
  __typename?: 'Fleet';
  fleetType: Scalars['String'];
  id: Scalars['ID'];
};

export type FleetNode = {
  id: Scalars['ID'];
};

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum RoleEnum {
  Active = 'ACTIVE',
  External = 'EXTERNAL',
  Guest = 'GUEST'
}

export type RootMutation = {
  __typename?: 'RootMutation';
  activateGuest?: Maybe<ActivateGuest>;
  activateTransporter?: Maybe<ActivateExternalTransporter>;
  demoteAdmin?: Maybe<DemoteAdmin>;
  setAssetStatus?: Maybe<SetAssetStatus>;
  updateAccountStatus?: Maybe<AccountStatus>;
  updateUser?: Maybe<UpdateUser>;
};


export type RootMutationActivateGuestArgs = {
  email: Scalars['String'];
};


export type RootMutationActivateTransporterArgs = {
  email: Scalars['String'];
};


export type RootMutationDemoteAdminArgs = {
  email: Scalars['String'];
};


export type RootMutationSetAssetStatusArgs = {
  assetType: Scalars['String'];
  isActive: Scalars['Boolean'];
  serial: Scalars['String'];
  transporter: Scalars['String'];
};


export type RootMutationUpdateAccountStatusArgs = {
  email: Scalars['String'];
  isSuspended: Scalars['Boolean'];
  suspensionReason?: InputMaybe<Scalars['String']>;
};


export type RootMutationUpdateUserArgs = {
  input: UserUpdate;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  allDrivers?: Maybe<Drivers>;
  allTrailers?: Maybe<Trailers>;
  allTrucks?: Maybe<Trucks_>;
  company?: Maybe<Company>;
  getUsers?: Maybe<UserConnection>;
  node?: Maybe<Node>;
};


export type RootQueryAllDriversArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  searchStr?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SourceEnum>;
};


export type RootQueryAllTrailersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  searchStr?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SourceEnum>;
};


export type RootQueryAllTrucksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  searchStr?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SourceEnum>;
};


export type RootQueryCompanyArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type RootQueryGetUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  role: RoleEnum;
  searchStr?: InputMaybe<Scalars['String']>;
};


export type RootQueryNodeArgs = {
  id: Scalars['ID'];
};

export type SetAssetStatus = {
  __typename?: 'SetAssetStatus';
  isActive?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export enum SourceEnum {
  LtaAdmin = 'LTA_ADMIN'
}

export type Trailer = {
  __typename?: 'Trailer';
  active?: Maybe<Scalars['Boolean']>;
  trailerCapacity?: Maybe<Scalars['String']>;
  trailerId?: Maybe<Scalars['Int']>;
  trailerRegistration?: Maybe<Scalars['String']>;
  trailerType?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
};

export type TrailerEdge = {
  __typename?: 'TrailerEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Trailer>;
};

export type Trailers = {
  __typename?: 'Trailers';
  edges: Array<Maybe<TrailerEdge>>;
  pageInfo?: Maybe<AssetPageInfo>;
};

export type Truck = {
  __typename?: 'Truck';
  active?: Maybe<Scalars['Boolean']>;
  assetId?: Maybe<Scalars['String']>;
  etaLoadingPoint?: Maybe<Scalars['String']>;
  lroId?: Maybe<Scalars['String']>;
  transporter?: Maybe<Scalars['String']>;
  truckReg?: Maybe<Scalars['String']>;
  woid?: Maybe<Scalars['String']>;
};

export type TruckEdge = {
  __typename?: 'TruckEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Truck>;
};

export type Trucks_ = {
  __typename?: 'Trucks_';
  edges: Array<Maybe<TruckEdge>>;
  pageInfo?: Maybe<AssetPageInfo>;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
};

export type User = UserNode & {
  __typename?: 'User';
  attributes?: Maybe<Attributes>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  username?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<UserEdge>>;
  extra?: Maybe<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node?: Maybe<User>;
  other?: Maybe<Scalars['String']>;
};

export type UserNode = {
  id: Scalars['ID'];
};

export type UserUpdate = {
  attributes?: InputMaybe<AuthUserAttributes>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type AccountDetailsFragment = { __typename?: 'Account', bankName?: string | null | undefined, accountName?: string | null | undefined, accountNumber?: string | null | undefined, accountType?: string | null | undefined, bankCode?: string | null | undefined, branchName?: string | null | undefined, swiftCode?: string | null | undefined, bankAddress?: string | null | undefined, beneficiaryName?: string | null | undefined, beneficiaryAddress?: string | null | undefined, currency?: string | null | undefined };

export type SetAssetStatusFragment = { __typename: 'SetAssetStatus', message?: string | null | undefined, serial?: string | null | undefined, isActive?: boolean | null | undefined, status?: number | null | undefined };

export type UploadedDocumentsFragment = { __typename?: 'Document', directorIdentification?: string | null | undefined, certificateOfIncorporation?: string | null | undefined, kraPin?: string | null | undefined, tin?: string | null | undefined, kraTcc?: string | null | undefined, proofOfDirectorship?: string | null | undefined, carriersLiability?: string | null | undefined, carrierResolution?: string | null | undefined, gitCoverConfirmation?: string | null | undefined, insurancePolicies?: string | null | undefined, beeCertificate?: string | null | undefined, bankLetter?: string | null | undefined, vatCertificate?: string | null | undefined, registrationCertificate?: string | null | undefined, taxClearanceCertificate?: string | null | undefined, companyLetterhead?: string | null | undefined, directorsIds?: string | null | undefined, letterOfGoodStanding?: string | null | undefined, vehicleRegistrationDocuments?: string | null | undefined, qualityManagementCertificate?: string | null | undefined, directorKraPin?: string | null | undefined, goodsInTransitCertificate?: string | null | undefined, signedLoriContract?: string | null | undefined };

export type UserDetailsFragment = { __typename?: 'User', id: string, username?: string | null | undefined, email?: string | null | undefined, attributes?: { __typename?: 'Attributes', transporterId?: number | null | undefined, transporter?: string | null | undefined, companyId?: number | null | undefined, companyMobile?: string | null | undefined, region?: string | null | undefined, accountSuspended?: boolean | null | undefined } | null | undefined };

export type ActivateExternalTransporterMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ActivateExternalTransporterMutation = { __typename?: 'RootMutation', activateTransporter?: { __typename?: 'ActivateExternalTransporter', message?: string | null | undefined, status?: number | null | undefined, username?: string | null | undefined, transporter?: string | null | undefined } | null | undefined };

export type ActivateGuestMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ActivateGuestMutation = { __typename?: 'RootMutation', activateGuest?: { __typename?: 'ActivateGuest', message?: string | null | undefined, status?: number | null | undefined, username?: string | null | undefined, transporter?: string | null | undefined } | null | undefined };

export type SetAssetStatusMutationVariables = Exact<{
  isActive: Scalars['Boolean'];
  serial: Scalars['String'];
  transporter: Scalars['String'];
  assetType: Scalars['String'];
}>;


export type SetAssetStatusMutation = { __typename?: 'RootMutation', setAssetStatus?: { __typename?: 'SetAssetStatus', message?: string | null | undefined, serial?: string | null | undefined, isActive?: boolean | null | undefined, status?: number | null | undefined } | null | undefined };

export type SuspendUserMutationVariables = Exact<{
  email: Scalars['String'];
  isSuspended: Scalars['Boolean'];
  suspensionReason: Scalars['String'];
}>;


export type SuspendUserMutation = { __typename?: 'RootMutation', updateAccountStatus?: { __typename?: 'AccountStatus', message?: string | null | undefined, status?: number | null | undefined, username?: string | null | undefined, transporter?: string | null | undefined, isSuspended?: boolean | null | undefined } | null | undefined };

export type GetUserDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserDetailsQuery = { __typename?: 'RootQuery', company?: { __typename?: 'Company', companyName: string, companyEmail: string, companyMobile: string, documents?: { __typename?: 'Document', directorIdentification?: string | null | undefined, certificateOfIncorporation?: string | null | undefined, kraPin?: string | null | undefined, tin?: string | null | undefined, kraTcc?: string | null | undefined, proofOfDirectorship?: string | null | undefined, carriersLiability?: string | null | undefined, carrierResolution?: string | null | undefined, gitCoverConfirmation?: string | null | undefined, insurancePolicies?: string | null | undefined, beeCertificate?: string | null | undefined, bankLetter?: string | null | undefined, vatCertificate?: string | null | undefined, registrationCertificate?: string | null | undefined, taxClearanceCertificate?: string | null | undefined, companyLetterhead?: string | null | undefined, directorsIds?: string | null | undefined, letterOfGoodStanding?: string | null | undefined, vehicleRegistrationDocuments?: string | null | undefined, qualityManagementCertificate?: string | null | undefined, directorKraPin?: string | null | undefined, goodsInTransitCertificate?: string | null | undefined, signedLoriContract?: string | null | undefined } | null | undefined, account?: { __typename?: 'Account', bankName?: string | null | undefined, accountName?: string | null | undefined, accountNumber?: string | null | undefined, accountType?: string | null | undefined, bankCode?: string | null | undefined, branchName?: string | null | undefined, swiftCode?: string | null | undefined, bankAddress?: string | null | undefined, beneficiaryName?: string | null | undefined, beneficiaryAddress?: string | null | undefined, currency?: string | null | undefined } | null | undefined } | null | undefined };

export type GetUsersQueryVariables = Exact<{
  role: RoleEnum;
  searchStr?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type GetUsersQuery = { __typename?: 'RootQuery', getUsers?: { __typename?: 'UserConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null | undefined }, edges: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, username?: string | null | undefined, email?: string | null | undefined, attributes?: { __typename?: 'Attributes', transporterId?: number | null | undefined, transporter?: string | null | undefined, companyId?: number | null | undefined, companyMobile?: string | null | undefined, region?: string | null | undefined, accountSuspended?: boolean | null | undefined } | null | undefined } | null | undefined } | null | undefined> } | null | undefined };

export const AccountDetailsFragmentDoc = gql`
    fragment accountDetails on Account {
  bankName
  accountName
  accountNumber
  accountType
  bankCode
  branchName
  swiftCode
  bankAddress
  beneficiaryName
  beneficiaryAddress
  currency
}
    `;
export const SetAssetStatusFragmentDoc = gql`
    fragment setAssetStatus on SetAssetStatus {
  __typename
  message
  serial
  isActive
  status
}
    `;
export const UploadedDocumentsFragmentDoc = gql`
    fragment uploadedDocuments on Document {
  directorIdentification
  certificateOfIncorporation
  kraPin
  tin
  kraTcc
  proofOfDirectorship
  carriersLiability
  carrierResolution
  gitCoverConfirmation
  insurancePolicies
  beeCertificate
  bankLetter
  vatCertificate
  registrationCertificate
  taxClearanceCertificate
  companyLetterhead
  directorsIds
  letterOfGoodStanding
  vehicleRegistrationDocuments
  qualityManagementCertificate
  directorKraPin
  goodsInTransitCertificate
  signedLoriContract
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment userDetails on User {
  id
  username
  email
  attributes {
    transporterId
    transporter
    companyId
    companyMobile
    region
    accountSuspended
  }
}
    `;
export const ActivateExternalTransporterDocument = gql`
    mutation activateExternalTransporter($email: String!) {
  activateTransporter(email: $email) {
    message
    status
    username
    transporter
  }
}
    `;
export type ActivateExternalTransporterMutationFn = Apollo.MutationFunction<ActivateExternalTransporterMutation, ActivateExternalTransporterMutationVariables>;

/**
 * __useActivateExternalTransporterMutation__
 *
 * To run a mutation, you first call `useActivateExternalTransporterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateExternalTransporterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateExternalTransporterMutation, { data, loading, error }] = useActivateExternalTransporterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useActivateExternalTransporterMutation(baseOptions?: Apollo.MutationHookOptions<ActivateExternalTransporterMutation, ActivateExternalTransporterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateExternalTransporterMutation, ActivateExternalTransporterMutationVariables>(ActivateExternalTransporterDocument, options);
      }
export type ActivateExternalTransporterMutationHookResult = ReturnType<typeof useActivateExternalTransporterMutation>;
export type ActivateExternalTransporterMutationResult = Apollo.MutationResult<ActivateExternalTransporterMutation>;
export type ActivateExternalTransporterMutationOptions = Apollo.BaseMutationOptions<ActivateExternalTransporterMutation, ActivateExternalTransporterMutationVariables>;
export const ActivateGuestDocument = gql`
    mutation activateGuest($email: String!) {
  activateGuest(email: $email) {
    message
    status
    username
    transporter
  }
}
    `;
export type ActivateGuestMutationFn = Apollo.MutationFunction<ActivateGuestMutation, ActivateGuestMutationVariables>;

/**
 * __useActivateGuestMutation__
 *
 * To run a mutation, you first call `useActivateGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateGuestMutation, { data, loading, error }] = useActivateGuestMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useActivateGuestMutation(baseOptions?: Apollo.MutationHookOptions<ActivateGuestMutation, ActivateGuestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateGuestMutation, ActivateGuestMutationVariables>(ActivateGuestDocument, options);
      }
export type ActivateGuestMutationHookResult = ReturnType<typeof useActivateGuestMutation>;
export type ActivateGuestMutationResult = Apollo.MutationResult<ActivateGuestMutation>;
export type ActivateGuestMutationOptions = Apollo.BaseMutationOptions<ActivateGuestMutation, ActivateGuestMutationVariables>;
export const SetAssetStatusDocument = gql`
    mutation setAssetStatus($isActive: Boolean!, $serial: String!, $transporter: String!, $assetType: String!) {
  setAssetStatus(isActive: $isActive, serial: $serial, transporter: $transporter, assetType: $assetType) {
    message
    serial
    isActive
    status
  }
}
    `;
export type SetAssetStatusMutationFn = Apollo.MutationFunction<SetAssetStatusMutation, SetAssetStatusMutationVariables>;

/**
 * __useSetAssetStatusMutation__
 *
 * To run a mutation, you first call `useSetAssetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAssetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAssetStatusMutation, { data, loading, error }] = useSetAssetStatusMutation({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      serial: // value for 'serial'
 *      transporter: // value for 'transporter'
 *      assetType: // value for 'assetType'
 *   },
 * });
 */
export function useSetAssetStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetAssetStatusMutation, SetAssetStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAssetStatusMutation, SetAssetStatusMutationVariables>(SetAssetStatusDocument, options);
      }
export type SetAssetStatusMutationHookResult = ReturnType<typeof useSetAssetStatusMutation>;
export type SetAssetStatusMutationResult = Apollo.MutationResult<SetAssetStatusMutation>;
export type SetAssetStatusMutationOptions = Apollo.BaseMutationOptions<SetAssetStatusMutation, SetAssetStatusMutationVariables>;
export const SuspendUserDocument = gql`
    mutation suspendUser($email: String!, $isSuspended: Boolean!, $suspensionReason: String!) {
  updateAccountStatus(email: $email, isSuspended: $isSuspended, suspensionReason: $suspensionReason) {
    message
    status
    username
    transporter
    isSuspended
  }
}
    `;
export type SuspendUserMutationFn = Apollo.MutationFunction<SuspendUserMutation, SuspendUserMutationVariables>;

/**
 * __useSuspendUserMutation__
 *
 * To run a mutation, you first call `useSuspendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendUserMutation, { data, loading, error }] = useSuspendUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isSuspended: // value for 'isSuspended'
 *      suspensionReason: // value for 'suspensionReason'
 *   },
 * });
 */
export function useSuspendUserMutation(baseOptions?: Apollo.MutationHookOptions<SuspendUserMutation, SuspendUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuspendUserMutation, SuspendUserMutationVariables>(SuspendUserDocument, options);
      }
export type SuspendUserMutationHookResult = ReturnType<typeof useSuspendUserMutation>;
export type SuspendUserMutationResult = Apollo.MutationResult<SuspendUserMutation>;
export type SuspendUserMutationOptions = Apollo.BaseMutationOptions<SuspendUserMutation, SuspendUserMutationVariables>;
export const GetUserDetailsDocument = gql`
    query getUserDetails($id: Int!) {
  company(id: $id) {
    companyName
    companyEmail
    companyMobile
    documents {
      ...uploadedDocuments
    }
    account {
      ...accountDetails
    }
  }
}
    ${UploadedDocumentsFragmentDoc}
${AccountDetailsFragmentDoc}`;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($role: RoleEnum!, $searchStr: String, $before: String, $after: String, $first: Int, $last: Int) {
  getUsers(role: $role, searchStr: $searchStr, before: $before, after: $after, first: $first, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...userDetails
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      role: // value for 'role'
 *      searchStr: // value for 'searchStr'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;