import { gql } from '@apollo/client';

export const deleteTrailer = gql`
	mutation deleteTrailer($id: Int!) {
		deleteTrailer(id: $id) {
			message
			status
		}
	}
`;
